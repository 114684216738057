import LazyImage from '@/components/lazy-image'
import Link from '@/components/link'
import { useCommonStore } from '@/store/common'
import { ThemeEnum } from '@/constants/base'
import { extractHeaderData } from '@/helper/layout/header'
import { useEffect, useState } from 'react'
import styles from './index.module.css'

type TLogo = {
  data: ReturnType<typeof extractHeaderData>
}

function Logo(props: TLogo) {
  const { data } = props
  const { theme, isMergeMode } = useCommonStore()
  const [url, setUrl] = useState<string>('')
  const renderImage = () => {
    return (
      <>
        {url ? (
          <img src={url} alt={data?.businessName} />
        ) : (
          <span
            style={{
              width: 30,
              height: 30,
            }}
          />
        )}
        <span className="ml-2 text-lg font-bold">{data?.businessName}</span>
      </>
    )
  }

  useEffect(() => {
    if (data) {
      setUrl(theme === ThemeEnum.dark ? data.imgWebLogo : data.imgWebWhiteLogo)
    }
  }, [theme])

  return isMergeMode ? (
    <span className={styles.scoped}>{renderImage()}</span>
  ) : (
    <Link href="/" className={styles.scoped}>
      {renderImage()}
    </Link>
  )
}

export default Logo
