import { recreationDecryptedAES } from '@/helper/ASE_RSA'
import { setToken } from '@/helper/auth'
import { baseCommonStore } from '@/store/common'
import { baseUserStore } from '@/store/user'
import { setLocale } from '@/helper/i18n'
import { I18nsEnum } from '@/constants/i18n'
import { getLangCache } from '@/helper/cache'
import { envIsDev, accessKeyId } from '@/helper/env'

export async function InitIdentity(pageContext: PageContext) {
  const { urlParsed } = pageContext
  // 本地开发环境不需要做身份验证
  if (envIsDev) return

  const { search } = urlParsed
  const { setTheme } = baseCommonStore.getState()
  const { clearUserCacheData, setIsTernaryOption } = baseUserStore.getState()
  /** token */
  const isRAToken = !!search?.isRAToken
  /** 融合模式判断 */
  const isMergeMode = !!search?.isMergeMode
  /** 多语言 */
  const locale = search?.locale as I18nsEnum

  const recreation = search?.recreation
  const redirect = search?.redirect
  // const theme = search?.theme

  /** 是否是 iframe 嵌套三元期权 */
  const isTernaryOption = search?.isTernaryOption
  const isIframe = window?.self !== window?.top
  isIframe && isTernaryOption && setIsTernaryOption(true)

  // theme && setTheme(theme)

  if (recreation) {
    await clearUserCacheData()
    /** 清空 url 参数 */
    const redirectUrl = redirect ? `?redirect=${redirect}` : ''
    history?.pushState({}, '', `${urlParsed?.pathname}${redirectUrl}`)
    if (locale && locale !== getLangCache()) {
      setLocale(locale)
    }
    return
  }

  if (isRAToken) {
    const { setAccessKey, setMergeMode, isMergeMode: mergeModeStatus } = baseCommonStore.getState()
    /** 融合模式需要设置 accesskey 到 header */
    if (isMergeMode) await setAccessKey(accessKeyId || '')
    await clearUserCacheData()
    /** 设置融合模式 tag */
    !mergeModeStatus && setMergeMode(isMergeMode)
    /** AES 解密 */
    const recreationInfo = recreationDecryptedAES(search?.isRAToken)

    const jsonToken = await JSON.parse(recreationInfo)

    jsonToken && setToken(jsonToken)
    /** 清空 url 参数 */
    history?.pushState({}, '', urlParsed?.pathname)

    if (locale && locale !== getLangCache()) {
      setLocale(locale)
    }
  }
}
