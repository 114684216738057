export const welcomeLocaleData = {
  'zh-CN': {
    'features_welcome_index_qbavhqn5lu': '欢迎来到',
    'future.funding-history.index-price.ingredient.column.exchange': '交易所',
  },
  'zh-HK': {
    'features_welcome_index_qbavhqn5lu': '歡迎來到',
    'future.funding-history.index-price.ingredient.column.exchange': '交易所',
  },
  'en-US': {
    'features_welcome_index_qbavhqn5lu': 'Welcome to',
    'future.funding-history.index-price.ingredient.column.exchange': 'Exchange',
  },
  'hi-IN': {
    'features_welcome_index_qbavhqn5lu': 'स्वागत है',
    'future.funding-history.index-price.ingredient.column.exchange': 'अदला-बदली"',
  },
  'id-ID': {
    'features_welcome_index_qbavhqn5lu': 'Selamat datang di',
    'future.funding-history.index-price.ingredient.column.exchange': 'menukarkan',
  },
  'ja-JP': {
    'features_welcome_index_qbavhqn5lu': 'ようこそ',
    'future.funding-history.index-price.ingredient.column.exchange': '交換',
  },
  'ko-KR': {
    'features_welcome_index_qbavhqn5lu': '환영합니다',
    'future.funding-history.index-price.ingredient.column.exchange': '교환',
  },
  'pt-BR': {
    'features_welcome_index_qbavhqn5lu': 'Bem-vindo a',
    'future.funding-history.index-price.ingredient.column.exchange': 'intercâmbio',
  },
  'ta-IN': {
    'features_welcome_index_qbavhqn5lu': 'வரவேற்க வாருங்கள்',
    'future.funding-history.index-price.ingredient.column.exchange': 'பரிமாற்றம்',
  },
  'th-TH': {
    'features_welcome_index_qbavhqn5lu': 'ยินดีต้อนรับสู่',
    'future.funding-history.index-price.ingredient.column.exchange': 'แลกเปลี่ยน',
  },
  'vi-VN': {
    'features_welcome_index_qbavhqn5lu': 'Chào mừng bạn đến với',
    'future.funding-history.index-price.ingredient.column.exchange': 'trao đổi',
  },
  'ms-MY': {
    'features_welcome_index_qbavhqn5lu': 'Selamat datang ke',
    'future.funding-history.index-price.ingredient.column.exchange': 'pertukaran',
  },
}
