import Icon from '@/components/icon'
import { t } from '@lingui/macro'
import { Trigger } from '@nbit/arco'
import LazyImage from '@/components/lazy-image'
import { useAssetsStore } from '@/store/assets'
import { rateFilter } from '@/helper/assets'
import { formatCurrency } from '@/helper/decimal'
import styles from './index.module.css'

function SingleCurrency(props) {
  const {
    walletModule: { updateWalletModule },
  } = useAssetsStore()
  const { currencyList, popupVisible, currentCurrency, setPopupVisible, isGameDetailsPage, walletData, raCurrency } =
    props

  const calculateTotal = () => {
    const rate = currentCurrency?.currencyEnName
    if (!walletData) return formatCurrency(walletData || 0, currentCurrency?.offset || 2, false)
    if (raCurrency?.currencyEnName === rate) return formatCurrency(walletData, currentCurrency?.offset || 2, false)
    return rateFilter({
      symbol: raCurrency?.currencyEnName,
      rate,
      amount: walletData,
      showUnit: false,
      isFormat: true,
    })
  }

  return (
    <Trigger
      popupVisible={popupVisible}
      className="top-tip"
      position="br"
      trigger={'click'}
      clickToClose
      popupAlign={{ bottom: [50, 16] }}
      popup={() => (
        <div className={styles['trigger-layout-menu-wrap']}>
          {currencyList?.map(item => {
            return (
              <div
                key={item?.currencyEnName}
                className={`items ${item?.currencyEnName === currentCurrency?.currencyEnName ? 'active-item' : ''}`}
                onClick={() => {
                  updateWalletModule({ currentFiat: item })
                  setPopupVisible(false)
                }}
              >
                <LazyImage className="fiat-icon" src={item?.currencyImg || ''} radius />
                {item?.currencyEnName}
              </div>
            )
          })}
        </div>
      )}
      onVisibleChange={setPopupVisible}
    >
      <div className="out-box">
        <div className="balance-style">
          <span className="balance-amount">
            {`${
              isGameDetailsPage
                ? t`features_layout_recreation_header_components_wallet_index_5f613dyizg`
                : calculateTotal() || ''
            } ${currentCurrency?.currencyEnName || '--'}`}
          </span>
        </div>
        <Icon
          name={popupVisible ? 'icon_arrow_up' : 'icon_arrow_down'}
          className="wallet-left-icon text-icon_color_03"
        />
      </div>
    </Trigger>
  )
}
export default SingleCurrency
