/** 钱包设置弹窗 */
import { Modal, Button, Switch, Message } from '@nbit/arco'
import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { useAssetsStore } from '@/store/assets'
import LazyImage from '@/components/lazy-image'
import Icon from '@/components/icon'
import { usePersonalCenterStore } from '@/store/user/personal-center'
import { postMemberBaseCurrencyType } from '@/apis/user'
import { oss_area_code_image_domain_address } from '@/constants/oss'
import style from './index.module.css'

function WalletSettingsModal(props) {
  const { fiatCurrencyData, updateFiatCurrencyData } = usePersonalCenterStore()
  const {
    walletModule: { updateWalletModule, currencyConvertStatus },
  } = useAssetsStore()
  const { visible, onClose } = props
  const [settingsInfo, setSettingsInfo] = useState({
    checked: currencyConvertStatus as boolean,
    currencyData: fiatCurrencyData?.currencyTypeCd as string | undefined,
  })

  /** 确定 */
  const onOk = () => {
    onClose()
  }
  /** 关闭 */
  const onCancel = () => {
    onClose()
  }

  const onCloseSave = () => {
    updateWalletModule({ currencyConvertStatus: settingsInfo.checked })
    onClose()
  }

  /** 开关事件 */
  const onChange = event => {
    setSettingsInfo({ ...settingsInfo, checked: event })
  }

  /** 复选框事件 */
  const onCheboxEvents = event => {
    settingsInfo.checked && setSettingsInfo({ ...settingsInfo, currencyData: event?.currencyTypeCd || '' })
  }

  /** 保存事件 */
  const onSubmit = async () => {
    if (settingsInfo.checked && settingsInfo?.currencyData !== fiatCurrencyData?.currencyTypeCd) {
      const res = await postMemberBaseCurrencyType({
        currencyTypeCd: settingsInfo?.currencyData || '',
      })
      if (res?.isOk && res?.data) {
        updateFiatCurrencyData('currencyTypeCd', settingsInfo?.currencyData || '')
        res?.data?.isSuccess && Message.success(t`features/user/personal-center/settings/converted-currency/index-0`)
      }
    }
    onCloseSave()
  }

  useEffect(() => {
    if (visible) {
      setSettingsInfo({ ...settingsInfo, currencyData: fiatCurrencyData.currencyTypeCd })
    }
  }, [visible])
  return (
    <Modal
      style={{ height: 552 }}
      closable={false}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      className={style['scoped-setting']}
    >
      <div className="setting-herder-box">
        <div className="setting-text">{t`features_recreation_theme_2z_home_page_multi_money_show_index_hvzb7tqyow`}</div>
        <div className="setting-icon">
          <Icon onClick={() => onClose()} name="close" />
        </div>
      </div>
      <div className="setting-encryption-box">
        <div className="setting-encryption">{t`features_recreation_theme_2z_home_page_multi_money_show_components_wallet_settings_modal_index_cfhvfokrcx`}</div>
        <div>
          <Switch className="setting-switch" checked={settingsInfo.checked} onChange={onChange} />
        </div>
      </div>
      <div className="setting-settlement">{t`features_recreation_theme_2z_home_page_multi_money_show_components_wallet_settings_modal_index_rjg9o4bsyy`}</div>
      <div className="setting-box">
        <div className="setting-list">
          {fiatCurrencyData?.currencyList?.map((currenyInfo, index) => {
            return (
              <div
                key={index}
                className={`setting-list-box ${
                  settingsInfo.checked ? 'setting-list-selected' : 'setting-list-unselected'
                }`}
              >
                <div>
                  {currenyInfo?.countryFlagImg && (
                    <LazyImage
                      whetherPlaceholdImg={false}
                      src={`${oss_area_code_image_domain_address}${currenyInfo?.countryFlagImg}.png`}
                    />
                  )}
                </div>
                <div className="setting-list-text">{currenyInfo?.currencyTypeCd || '--'}</div>
                <div>
                  <Icon
                    onClick={() => onCheboxEvents(currenyInfo)}
                    name={
                      settingsInfo?.currencyData === currenyInfo?.currencyTypeCd
                        ? 'login_verify_selected'
                        : 'icon_unselected'
                    }
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="setting-notice">{t`features_recreation_theme_2z_home_page_multi_money_show_components_wallet_settings_modal_index_1kpuyyidzv`}</div>
      <Button
        className="setting-button"
        onClick={() => {
          onSubmit()
        }}
      >
        {t`components_chart_header_data_2622`}
      </Button>
    </Modal>
  )
}

export default WalletSettingsModal
