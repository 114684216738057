import { getPersonalAccountSecurityPath } from '@/helper/route'
import { getAgentCenterRoutePath, getAgentInviteRoutePath } from '@/helper/route/agent'
import { getSettingsRoutePath, getWelfareCenterRoutePath } from '@/helper/route/user'
import { getIsDemoUser } from '@/helper/user'
import { t } from '@lingui/macro'

/**
 * 获取个人信息菜单列表
 */
export const getUserInfoMenus = (isLogin: boolean, isDemoUser: boolean, handleLoginOut: () => void) => {
  return [
    {
      isDisabled: !isLogin,
      name: t`features/user/personal-center/profile/index-9`,
      id: 'user',
      router: '',
      icon: 'user_head_v2',
      isIcon: true,
      isShowDown: true,
      iconUp: false,
    },
    {
      isDisabled: !isLogin,
      icon: 'user_down_security_v2',
      name: t`user.personal_center_09`,
      router: getPersonalAccountSecurityPath(),
      id: 'security',
      isIcon: true,
      downShow: true,
      isHide: true,
      isHideMenu: isDemoUser,
    },
    {
      isDisabled: !isLogin,
      icon: 'icon_personal_public_gift_v2',
      name: t`user.personal_center_05`,
      router: getAgentInviteRoutePath(),
      id: 'public',
      isIcon: true,
      downShow: true,
      isHide: true,
      isHideMenu: isDemoUser,
    },
    {
      isDisabled: !isLogin,
      icon: 'icon_alliance_center_v2',
      name: t`modules_agent_agency_center_index_page_jyovg1cnix`,
      router: getAgentCenterRoutePath(),
      id: 'alliance',
      isIcon: true,
      downShow: true,
      isHide: true,
      isHideMenu: isDemoUser,
    },
    {
      isDisabled: !isLogin,
      icon: 'icon_personal_public_ticket_v2',
      name: t`features_welfare_center_index_vg4pz_d6jn`,
      router: getWelfareCenterRoutePath(),
      id: 'welfare',
      isIcon: true,
      downShow: true,
      isHide: true,
      isHideMenu: isDemoUser,
    },
    {
      isDisabled: !isLogin,
      icon: 'user_anatar_site_icon_v2',
      name: t`features_trade_trade_setting_index_2510`,
      router: getSettingsRoutePath(),
      id: 'user_anatarSettings',
      isIcon: true,
      downShow: true,
      isHide: true,
    },
    {
      isDisabled: !isLogin,
      icon: 'icon_user_quit_v2',
      name: t`features_layout_content_compnent_layout_menu_menu_main_index_s8lflz8r9z`,
      onclick: () => {
        handleLoginOut()
      },
      id: 'settings',
      isIcon: true,
      downShow: true,
      isHide: true,
    },
  ].filter(item => !item.isHideMenu)
}
