import { useLayoutStore } from '@/store/layout'
import { useEffect } from 'react'
import { link } from '@/helper/link'
import { businessId } from '@/helper/env'
import { useCommonStore } from '@/store/common'
// import LoginModal from '@/features/message-center/login-modal'
import { useGuidePageInfo } from '@/hooks/features/layout'
import { getGuidePageComponentInfoByKey, getHomePageConfig } from '@/helper/layout'
import { useGameStore } from '@/store/game'
import { getBasicWebApiData } from '@/apis/layout'
import { MaintenanceEnum } from '@/constants/maintenance'
import { usePageContext } from '@/hooks/use-page-context'
import { maintenancePath, getGameLobbyRoutePath } from '@/helper/route/game'
import { extractHeaderData } from '@/helper/layout/header'
import { getLangCache, getCacheLayoutHeader } from '@/helper/cache'
import ShouldGuidePageComponentDisplay from '@/features/recreation/component/component-should-display'
import Personalization from '@/features/recreation/theme/ok/layout/recreation-header/components/personalization'
import HeaderMenu from '@/features/recreation/theme/ok/layout/components/header-menu'
import Logo from '@/features/recreation/theme/ok/layout/components/logo'
import SwitchGame from '@/features/recreation/component/switch-game'
import Styles from './header.module.css'

function Header() {
  const pageContext = usePageContext()
  const cacheLayoutHeader = getCacheLayoutHeader()
  const { maintenance, setMaintenanceStatus } = useGameStore()

  const guidePage = useGuidePageInfo()
  const formattedLandingPageSection = getHomePageConfig(guidePage)
  const { pageInfoTopBar = [] } = guidePage
  const { pageInfoTopBar: headerConfig } = formattedLandingPageSection || {}
  const homeIcon = getGuidePageComponentInfoByKey('homeIcon', pageInfoTopBar)

  const langCache = getLangCache()
  // 是否窄屏，小于 1200 为窄屏
  const { narrowScreen } = useLayoutStore()
  const { locale, setIsRouterRecreation } = useCommonStore()
  const { headerData, setHeaderData } = useLayoutStore()
  const handleLogo = async () => {
    if (!cacheLayoutHeader?.imgWebLogo) {
      const params = {
        businessId,
        lanType: locale || langCache,
      }
      const { data, isOk } = await getBasicWebApiData(params)
      if (!isOk && !data) return
      const newData = { ...data } as any
      const header = extractHeaderData(newData)
      setHeaderData(header)
    }
  }

  const handleGameRouter = () => {
    const path = pageContext?.path
    if (maintenance === MaintenanceEnum.normal) {
      const isGame = path === maintenancePath()
      isGame && link(getGameLobbyRoutePath())
      return
    }
    return link(maintenancePath())
  }

  useEffect(() => {
    handleGameRouter()
  }, [maintenance])

  useEffect(() => {
    handleLogo()
    setMaintenanceStatus()
    setIsRouterRecreation(true)
  }, [])

  return (
    <ShouldGuidePageComponentDisplay {...headerConfig}>
      <div className={Styles.scoped}>
        <div className="header-left">
          <div className="home-wrap">
            <ShouldGuidePageComponentDisplay {...homeIcon}>
              <Logo data={headerData} />
            </ShouldGuidePageComponentDisplay>
          </div>

          {maintenance !== MaintenanceEnum.no_show && <SwitchGame />}

          {!narrowScreen && <HeaderMenu />}
        </div>

        <Personalization />
      </div>
    </ShouldGuidePageComponentDisplay>
  )
}

export default Header
