import { Button, Menu } from '@nbit/arco'
import Icon from '@/components/icon'
import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import { useUserStore } from '@/store/user'
import { useLayoutStore } from '@/store/layout'
import { I18nsMap } from '@/constants/i18n'
import { useCommonStore } from '@/store/common'
import Wallet from '@/features/recreation/theme/ok/layout/recreation-header/components/wallet'
import GameList from '@/features/recreation/theme/ok/layout/recreation-header/components/drawer-content/game-list'
import { showLocaleInfoContent } from '@/constants/common'
import styles from './index.module.css'

function DrawerContent(props) {
  const { setTrialAccountInfo } = useUserStore()
  const { locale, localeInfo } = useCommonStore()
  const userStore = useUserStore()
  const { headerData } = useLayoutStore()

  return (
    <div className={styles.scoped}>
      {userStore?.isLogin ? (
        <div className="flex justify-center">
          <Wallet />
        </div>
      ) : (
        <div className="btn-box">
          <Button
            className="register-btn"
            type="primary"
            onClick={() => link('/register')}
          >{t`features_home_game_details_component_game_screen_index_k8_dapa8uu`}</Button>
          <Button
            type="outline"
            className="try-btn"
            onClick={() => setTrialAccountInfo()}
          >{t`features_user_register_index_wvdg2uy5cw`}</Button>
        </div>
      )}

      <GameList />

      <div className="list-box mt-0">
        <Menu className="menu" selectable={false}>
          {userStore?.isLogin ? (
            <Menu.Item key="1">
              <div
                onClick={() => link('/inmail')}
              >{t`features_user_personal_center_settings_inmail_setting_index_5101255`}</div>
            </Menu.Item>
          ) : null}

          <Menu.Item key="2">
            <div className="language" onClick={props.openI18nSelect}>
              <p>{t`features/layout/components/personalization-0`}</p>
              <div className="flex">
                <span className="current-language">{I18nsMap[locale]}</span>
                <Icon name="icon_arrow_right" className="icon" />
              </div>
            </div>
          </Menu.Item>
        </Menu>
      </div>

      {showLocaleInfoContent(localeInfo?.showDownloadView) && (
        <div className="download">
          <Button className="download-btn" type="outline" shape="round" onClick={() => link('/download')}>
            {t`Download`} {headerData?.businessName}
          </Button>
        </div>
      )}
    </div>
  )
}

export default DrawerContent
