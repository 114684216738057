import Icon from '@/components/icon'
import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import { ThemeEnum } from '@nbit/chart-utils'
import { useCommonStore } from '@/store/common'
import { useRequest } from 'ahooks'
import { cloneDeep } from 'lodash'
import { useState, useEffect } from 'react'
import { Trigger, Spin } from '@nbit/arco'
import { getGamesRoutePath, getGameGroupRoutePath } from '@/helper/route/game'
import { Type } from '@/components/lazy-image'
import NoDataImage from '@/components/no-data-image'
import { RaHallGroupTypeProps } from '@/typings/api/game'
import { HallGameGroupTypeEnum } from '@/helper/game'
import { LayoutScrollTop } from '@/constants/game'
import { getGameTypeList, getGameTypeData } from '@/apis/game'
import { useGameStore } from '@/store/game'
import { MaintenanceEnum } from '@/constants/maintenance'
import { useRaSettingsStore } from '@/store/settings'
import HorizontalScrollBar from '@/features/recreation/component/menu'

function SubMenu(route) {
  const { maintenance } = useGameStore()
  return (
    <div
      className="submenu-wrap"
      onClick={() => {
        if (maintenance !== MaintenanceEnum.normal) return
        LayoutScrollTop()
        if (route?.direct === HallGameGroupTypeEnum.yes) {
          return link(
            route?.id ? getGamesRoutePath(route?.id, route?.groupId) : getGamesRoutePath(route?.id ?? route?.groupId)
          )
        }

        if (route?.direct === HallGameGroupTypeEnum.no) {
          return link(route?.id ? getGamesRoutePath(route?.id, route?.groupId) : getGameGroupRoutePath(route))
        }

        return link(getGameGroupRoutePath(route))
      }}
    >
      {route.name ?? route.groupName}
    </div>
  )
}

function HeaderMenu() {
  const { theme } = useCommonStore()
  const { maintenance } = useGameStore()
  const [menuGropData, setMenuGropData] = useState<RaHallGroupTypeProps[]>([])
  const {
    raSettings: { walletModeCd },
  } = useRaSettingsStore()

  const headerGroupType = async () => {
    const { data, isOk } = await getGameTypeList({
      sectionCd: 'top',
    })
    if (data && isOk) {
      const newData = data?.map(item => {
        return {
          ...item,
          isCheckout: false,
          submenu: [],
        }
      })
      setMenuGropData(newData)
    }
  }

  const getGroupList = async id => {
    const params = {
      typeCd: id,
      showDirect: '1',
      pageSize: 100,
    }
    const { data, isOk } = await getGameTypeData(params)
    if (isOk && data) {
      const newData = cloneDeep(menuGropData)
      const handleData = newData?.map(item => {
        if (item?.id === id) {
          return {
            ...item,
            submenu: data?.list,
            isCheckout: true,
          }
        }
        return {
          ...item,
        }
      })
      setMenuGropData(handleData)
    }
  }

  const { run, loading } = useRequest(getGroupList, { manual: true })

  const onTriggerChange = item => {
    if (!item?.submenu?.length && !item?.isCheckout && item?.id) {
      run(item?.id)
    }
  }

  useEffect(() => {
    headerGroupType()
  }, [walletModeCd])

  return (
    <div className="menu-wrap">
      <HorizontalScrollBar>
        {menuGropData
          .sort((a, b) => a.sortCode - b.sortCode)
          .map((route, idx) => {
            return (
              <div className="menu-item-wrap" key={idx}>
                <Trigger
                  popupAlign={{
                    bottom: 16,
                  }}
                  popup={() => {
                    return (
                      <div
                        className={`popup-wrap recreation-header-menu-wrap ${
                          theme === ThemeEnum.dark ? 'dark-spin' : 'light-spin'
                        }`}
                      >
                        {maintenance !== MaintenanceEnum.normal ? (
                          <NoDataImage
                            type={Type.png}
                            footerText={t`features_entertainment_area_quota_conversion_index_ueleprpp22`}
                            name="image_default_graph"
                          />
                        ) : !route.submenu?.length && route?.isCheckout ? (
                          <NoDataImage name="image_default_graph" />
                        ) : loading ? (
                          <Spin loading={loading} />
                        ) : (
                          route.submenu?.map((subRoute, index) => {
                            return <SubMenu key={index} {...subRoute} />
                          })
                        )}
                      </div>
                    )
                  }}
                  onVisibleChange={(flag: boolean) => {
                    if (!flag || maintenance !== MaintenanceEnum.normal) return
                    onTriggerChange(route)
                  }}
                >
                  <div className="menu-items">
                    <label>{route.name}</label>
                    <Icon className="ml-1 multiple-menu-icon" name="arrow_open" hasTheme />
                  </div>
                </Trigger>
              </div>
            )
          })}
        {/* <div className="menu-item-wrap">
          <div className="menu-item-wrap menu-items cursor-pointer" onClick={handleJumpTernaryOptions}>
            <label className="cursor-pointer">{t`features_trade_trade_header_pop_menu_index_pweylulaexiyla3oov_3y`}</label>
          </div>
        </div> */}
      </HorizontalScrollBar>
    </div>
  )
}

export default HeaderMenu
