import { useLayoutStore } from '@/store/layout'
import { useCommonStore } from '@/store/common'
import { useEffect } from 'react'
import LoginModal from '@/features/message-center/login-modal'
import { useGuidePageInfo } from '@/hooks/features/layout'
import { getGuidePageComponentInfoByKey, getHomePageConfig } from '@/helper/layout'
import { useGameStore } from '@/store/game'
import { MaintenanceEnum } from '@/constants/maintenance'
import ShouldGuidePageComponentDisplay from '@/features/home/common/component-should-display'
import SwitchGame from '@/features/recreation/component/switch-game'
import HeaderMenu from '@/features/recreation/theme/ok/layout/components/header-menu'
import Personalization from '@/features/recreation/theme/ok/layout/recreation-header/components/personalization'
import Logo from '@/features/recreation/theme/ok/layout/components/logo'
import Styles from './header.module.css'

function Header() {
  const { headerData } = useLayoutStore()
  const { setIsRouterRecreation } = useCommonStore()
  const { maintenance, setMaintenanceStatus } = useGameStore()

  const guidePage = useGuidePageInfo()
  const formattedLandingPageSection = getHomePageConfig(guidePage)
  const { pageInfoTopBar = [] } = guidePage

  const { pageInfoTopBar: headerConfig } = formattedLandingPageSection || {}

  const homeIcon = getGuidePageComponentInfoByKey('homeIcon', pageInfoTopBar)

  useEffect(() => {
    setMaintenanceStatus()
    setIsRouterRecreation(false)
  }, [])

  return (
    <ShouldGuidePageComponentDisplay {...headerConfig}>
      <div className={Styles.scoped}>
        <div className="header-left">
          <div className="home-wrap">
            <ShouldGuidePageComponentDisplay {...homeIcon}>
              <Logo data={headerData} />
            </ShouldGuidePageComponentDisplay>
          </div>

          {maintenance !== MaintenanceEnum.no_show && <SwitchGame />}

          <HeaderMenu />
        </div>

        <Personalization />
        <LoginModal />
      </div>
    </ShouldGuidePageComponentDisplay>
  )
}

export default Header
