export const welcomeLocaleData = {
  'zh-CN': {
    features_welcome_index_qbavhqn5lu: '欢迎来到',
    features_layout_header_components_switch_game_index_3jxunqhhyq: '娱乐区',
  },
  'zh-HK': {
    features_welcome_index_qbavhqn5lu: '歡迎來到',
    features_layout_header_components_switch_game_index_3jxunqhhyq: '娛樂區',
  },
  'en-US': {
    features_welcome_index_qbavhqn5lu: 'Welcome to',
    features_layout_header_components_switch_game_index_3jxunqhhyq: 'Leisure Area',
  },
  'hi-IN': {
    features_welcome_index_qbavhqn5lu: 'स्वागत है',
    features_layout_header_components_switch_game_index_3jxunqhhyq: 'मनोरंजन नगर',
  },
  'id-ID': {
    features_welcome_index_qbavhqn5lu: 'Selamat datang di',
    features_layout_header_components_switch_game_index_3jxunqhhyq: 'Hiburan',
  },
  'ja-JP': {
    features_welcome_index_qbavhqn5lu: 'ようこそ',
    features_layout_header_components_switch_game_index_3jxunqhhyq: '娯楽の街',
  },
  'ko-KR': {
    features_welcome_index_qbavhqn5lu: '환영합니다',
    features_layout_header_components_switch_game_index_3jxunqhhyq: '오락 지구',
  },
  'pt-BR': {
    features_welcome_index_qbavhqn5lu: 'Bem-vindo a',
    features_layout_header_components_switch_game_index_3jxunqhhyq: 'Divertido',
  },
  'ta-IN': {
    features_welcome_index_qbavhqn5lu: 'வரவேற்க வாருங்கள்',
    features_layout_header_components_switch_game_index_3jxunqhhyq: 'கிராம விளக்கு',
  },
  'th-TH': {
    features_welcome_index_qbavhqn5lu: 'ยินดีต้อนรับสู่',
    features_layout_header_components_switch_game_index_3jxunqhhyq: 'เเขตบันเทิง',
  },
  'vi-VN': {
    features_welcome_index_qbavhqn5lu: 'Chào mừng bạn đến với',
    features_layout_header_components_switch_game_index_3jxunqhhyq: 'Khu giải trí',
  },
  'ms-MY': {
    features_welcome_index_qbavhqn5lu: 'Selamat datang ke',
    features_layout_header_components_switch_game_index_3jxunqhhyq: 'kawasan hiburan',
  },
}
