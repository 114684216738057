import { Trigger, Input } from '@nbit/arco'
import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { getCoinAssetData } from '@/apis/assets/main'
import Icon from '@/components/icon'
import { AssetsListResp } from '@/typings/api/assets/assets'
import { useAssetsStore } from '@/store/assets'
import { rateFilter } from '@/helper/assets'
import { usePersonalCenterStore } from '@/store/user/personal-center'
import { formatCurrency } from '@/helper/decimal'
import { useDebounce } from 'ahooks'
import { PostMemberCoinWallet, getMemberCoinWallet } from '@/apis/entertainment-area'
import { AssetsScenesTypeEnum } from '@/constants/assets'
import LazyImage from '@/components/lazy-image'
import { usePageContext } from '@/hooks/use-page-context'
import { getGamesRoutePath } from '@/helper/route/game'
import { AssetsC2CListResp } from '@/typings/api/assets/c2c'
import WalletSettingsModal from './wallet-settings-modal'
import style from './index.module.css'

function MultiCurrency(props) {
  const { fiatCurrencyData, getFiatCurrencyData } = usePersonalCenterStore()
  const {
    walletModule: { currencyConvertStatus, updateWalletModule, currencyCoin },
  } = useAssetsStore()
  const { isGameDetailsPage } = props
  const [popup, setPopup] = useState({
    popupVisible: false as boolean,
    value: '' as string,
    visible: false as boolean, // 钱包设置弹窗
    list: [] as AssetsListResp[],
    oldList: [] as AssetsListResp[],
  })
  const pageData = usePageContext()

  const debouncedSearchKey = useDebounce(popup?.value, {
    wait: 300,
  })

  /** 法币汇率折合排序 */
  function sortCurrencyAssetsFn(a: AssetsListResp, b: AssetsListResp) {
    return (b.usdBalance as unknown as number) - (a.usdBalance as unknown as number)
  }

  const searchList =
    (popup?.list &&
      popup?.list
        ?.filter(item => {
          const ignoreCaseKey = debouncedSearchKey && debouncedSearchKey.toUpperCase()
          const { coinName = '', coinFullName } = item || {}
          return (
            coinName &&
            coinFullName &&
            (coinName?.toUpperCase().includes(ignoreCaseKey) || coinFullName?.toUpperCase().includes(ignoreCaseKey))
          )
        })
        .sort(sortCurrencyAssetsFn)) ||
    []

  /** 币种搜索事件 */
  const onInputChange = e => {
    setPopup(() => {
      const val = { ...popup }
      val.value = e
      return val
    })
  }
  /** 当前选中事件 */
  const onPopClick = async e => {
    const res = await PostMemberCoinWallet({ coinId: e?.coinId })
    if (res.isOk && res?.data?.isSuccess) {
      updateWalletModule({ currencyCoin: e })
      setPopup({ ...popup, popupVisible: false })
    }
  }
  /** 钱包设置事件 */
  const onWalletSettings = () => {
    setPopup({ ...popup, popupVisible: false, visible: true })
  }

  /** 获取默认币种 */
  const queryMemberCoinWallet = async list => {
    const res = await getMemberCoinWallet({})
    if (res.isOk && res?.data) {
      updateWalletModule({ currencyCoin: list?.find(row => row?.symbol === res?.data?.coinName) || {} })
    }
  }

  /** 获取钱包设置 */
  const getCoinAssetsListData = async () => {
    const res = await getCoinAssetData({
      isGt: false,
      pageNum: 1,
      pageSize: 0,
      scenes: String(AssetsScenesTypeEnum.recreation),
    })
    if (res.isOk && res?.data) {
      setPopup({ ...popup, list: res?.data?.list || [], oldList: res?.data?.list || [] })
      queryMemberCoinWallet(res?.data?.list)
    }
  }

  useEffect(() => {
    if (popup.popupVisible) {
      getFiatCurrencyData()
    }
  }, [popup.popupVisible])

  useEffect(() => {
    getCoinAssetsListData()
  }, [])

  return (
    <div className={style['scoped-multi']}>
      <Trigger
        className="top-tip"
        onVisibleChange={() => {
          setPopup({ ...popup, popupVisible: !popup.popupVisible })
        }}
        popup={() => {
          return (
            <div className={style['multi-menu-wrap']}>
              <div className="multi-search">
                <Input
                  onChange={e => {
                    onInputChange(e)
                  }}
                  placeholder={t`features_assets_financial_record_search_form_index_2746`}
                  prefix={<Icon name="search" className="icon-search" />}
                  value={popup.value}
                />
              </div>
              <div className="items-box">
                {searchList.length > 0 &&
                  searchList?.map((coinInfo, index) => {
                    return (
                      <div
                        key={index}
                        className={`items ${currencyCoin?.symbol === coinInfo?.symbol ? 'item-selected' : ''}`}
                        onClick={() => onPopClick(coinInfo)}
                      >
                        <div className="fiat-icon">
                          {currencyConvertStatus
                            ? `${fiatCurrencyData?.currencySymbol}${formatCurrency(
                                rateFilter({
                                  symbol: coinInfo?.symbol,
                                  amount: coinInfo?.totalAmount,
                                  showUnit: false,
                                  isFormat: false,
                                }),
                                Number(fiatCurrencyData?.currencyPrecision),
                                false
                              )}`
                            : formatCurrency(coinInfo?.totalAmount, coinInfo?.calcPrecision, false)}
                        </div>
                        {coinInfo?.webLogo && <LazyImage src={coinInfo?.webLogo} />}
                        <div>{coinInfo?.coinName || ''}</div>
                      </div>
                    )
                  })}
              </div>
              <div className="multi-wallet-settings">
                <div className="settings-text" onClick={onWalletSettings}>
                  {t`features_recreation_theme_2z_home_page_multi_money_show_index_hvzb7tqyow`}
                </div>
              </div>
            </div>
          )
        }}
        popupAlign={{ bottom: [206, 16] }}
        clickToClose
        trigger={'click'}
        popupVisible={popup.popupVisible}
        position="br"
      >
        <div className="out-box">
          <div className="balance-style">
            <span className="balance-amount herder-flex">
              <div>
                {`${
                  isGameDetailsPage
                    ? `${t`features_layout_recreation_header_components_wallet_index_5f613dyizg`} ${
                        currencyCoin?.coinName || ''
                      }`
                    : `${
                        currencyConvertStatus
                          ? `${fiatCurrencyData?.currencySymbol || ''}${formatCurrency(
                              rateFilter({
                                symbol: currencyCoin?.symbol,
                                amount: currencyCoin?.totalAmount,
                                showUnit: false,
                                isFormat: false,
                              }),
                              Number(fiatCurrencyData?.currencyPrecision),
                              false
                            )}`
                          : formatCurrency(currencyCoin?.totalAmount, currencyCoin?.calcPrecision, false)
                      }`
                } 
                
              `}
              </div>
              <div>{currencyCoin?.webLogo && <img src={currencyCoin?.webLogo} alt="" />}</div>
            </span>
          </div>
          <Icon
            name={popup.popupVisible ? 'icon_arrow_up' : 'icon_arrow_down'}
            className="wallet-left-icon text-icon_color_03"
          />
        </div>
      </Trigger>
      {popup?.visible && (
        <WalletSettingsModal
          oldList={popup?.oldList}
          visible={popup.visible}
          onClose={() => setPopup({ ...popup, visible: false })}
        />
      )}
    </div>
  )
}
export default MultiCurrency
