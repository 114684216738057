import { t } from '@lingui/macro'
import { useState, useEffect } from 'react'
import { Message } from '@nbit/arco'
import { getUserBalance } from '@/apis/entertainment-area'
import { usePersonalCenterStore } from '@/store/user/personal-center'
import { useMemoizedFn, useUnmount, useUpdateEffect, useMount } from 'ahooks'
import { formatCurrency } from '@/helper/decimal'
import { getDetermineWhetherMethods, rateFilter } from '@/helper/assets'
import { useAssetsStore } from '@/store/assets'
import { Asset_Body } from '@/plugins/ws/protobuf/ts/proto/Asset'
import { useAssetsFiatStore } from '@/store/assets/fiat'
import { DepositSidebarSetting } from '@/features/user/components/sidebar'
import UserMenuNavigation from '@/features/assets/common/menu-navigation/index'
import { postMemberBaseCurrencyType } from '@/apis/user'
import { getIsDemoUser } from '@/helper/user'
import { oss_area_code_image_domain_address } from '@/constants/oss'
import { showFiatCurrency } from '@/helper/env'
import { usePageContext } from '@/hooks/use-page-context'
import { getIsLogin } from '@/helper/auth'
import { CurrencyModeErationEnum } from '@/constants/common'
import { useCommonStore } from '@/store/common'
import { getIsSingleFaitMode } from '@/helper/assets/fiat'
import WalletCurrency from '@/features/layout/recreation-header/components/wallet-currency'
import styles from './index.module.css'

export interface ICurrency {
  currencyEnName: string
  offset: number
  currencyTypeCd?: string
  currencyImg?: string
}

export default function Wallet(props) {
  const isLogin = getIsLogin()
  const { localeInfo } = useCommonStore()
  const { hiddenMenus } = props
  const [walletData, setWalletData] = useState<number>(0)
  const {
    walletModule: { currentFiat: currentCurrency, updateWalletModule, currencyCoin },
    allCoinList,
    merchantInfo,
    fetchCoinRate,
    wsSpotAssetsSubscribe,
    wsSpotAssetsUnSubscribe,
    setMerchantInfo,
  } = useAssetsStore()

  const { fiatCurrencyData, getFiatCurrencyData, updateFiatCurrencyData } = usePersonalCenterStore()
  const [currencyList, setCurrencyList] = useState<ICurrency[]>([]) // 法币 + 结算币种列表
  const [raCurrency, setRaCurrency] = useState<ICurrency>({} as ICurrency) // 当前结算币种
  const [totalAmount, setTotalAmount] = useState('') // 换算后的总额
  const [popupVisible, setPopupVisible] = useState(false)
  const [visible, setVisible] = useState(false)
  const { faitEnums, faitFuturesEnums } = useAssetsFiatStore() || {}

  const pageContext = usePageContext()
  const pathname = pageContext.path

  /** 当前页面是否为游戏详情页面 */
  const isGameDetailsPage = pathname.includes('recreation/game-details')

  useMount(faitFuturesEnums)
  const paymentColor = faitEnums.c2cPaymentColorEnum.enums
  const paymentMethod = faitEnums.paymentDicCodeEnum.enums

  /** 获取三方默认结算币种或者单法币站点默认法币图标 */
  const onGetRaCurrencyImg = () => {
    if (getIsSingleFaitMode()) {
      let countryFlagImg =
        fiatCurrencyData?.currencyList?.find(e => e.currencyEnName === raCurrency.currencyEnName)?.countryFlagImg || ''

      return `${oss_area_code_image_domain_address}${countryFlagImg}.png` || ''
    }

    return allCoinList?.find(e => e.symbol === raCurrency.currencyEnName)?.appLogo || ''
  }

  const getWalletData = async () => {
    const { data, isOk } = await getUserBalance({
      ignoreGroup: true,
    })
    if (data && isOk) {
      setWalletData(data?.walletBalance as number)
      setRaCurrency({
        currencyEnName: data?.walletBalanceCoin || '',
        offset: data?.walletAccuracy || 2,
      })
    }
  }

  /**
   * 根据当前选中法币计算总额
   */
  const calculateTotal = () => {
    const rate = getIsSingleFaitMode() ? merchantInfo?.symbol : currentCurrency?.currencyEnName

    if (!walletData)
      return formatCurrency(
        walletData || 0,
        getIsSingleFaitMode() ? merchantInfo?.coinPrecision : currentCurrency?.offset || 2,
        false
      )

    if (raCurrency?.currencyEnName === rate)
      return formatCurrency(
        walletData,
        getIsSingleFaitMode() ? merchantInfo?.coinPrecision : currentCurrency?.offset || 2,
        false
      )

    return rateFilter({
      symbol: raCurrency?.currencyEnName,
      rate,
      amount: walletData,
      showUnit: false,
      isFormat: true,
    })
  }

  const coinWSCallBack = useMemoizedFn((data: Asset_Body[]) => {
    if (!data || data?.length === 0) return
    getWalletData()
  })

  /** 更新用户设置折算法币 */
  const onChangeUserCurrency = async () => {
    const res = await postMemberBaseCurrencyType({ currencyTypeCd: currentCurrency?.currencyTypeCd || '' })
    if (res.isOk && res.data?.isSuccess) updateFiatCurrencyData('currencyTypeCd', currentCurrency?.currencyTypeCd || '')
  }

  // 游戏动画延迟 获取用户余额
  useEffect(() => {
    fetchCoinRate()
    getWalletData()
    getFiatCurrencyData()
    wsSpotAssetsSubscribe(coinWSCallBack)
  }, [])

  useUpdateEffect(() => {
    if (!raCurrency?.currencyEnName) return

    let settlementCurrency: ICurrency[] = showFiatCurrency ? [{ ...raCurrency, currencyImg: onGetRaCurrencyImg() }] : []

    const memberCurrency: ICurrency[] =
      fiatCurrencyData?.currencyList?.map(item => {
        return {
          currencyEnName: item?.currencyEnName || '',
          offset: 2,
          currencyTypeCd: item?.currencyTypeCd,
          currencyImg: `${oss_area_code_image_domain_address}${item.countryFlagImg}.png` || '',
        }
      }) || []

    const isRepeat = memberCurrency?.some(item => item.currencyEnName === raCurrency?.currencyEnName)
    const newAgentCurrencyList = isRepeat ? memberCurrency : [...settlementCurrency, ...memberCurrency]

    if (
      !currentCurrency?.currencyEnName ||
      !newAgentCurrencyList?.some(item => item.currencyEnName === currentCurrency?.currencyEnName)
    ) {
      const userCurrency = getIsSingleFaitMode()
        ? settlementCurrency?.[0]
        : newAgentCurrencyList.find(item => item.currencyEnName === fiatCurrencyData.currencyEnName)

      updateWalletModule({ currentFiat: userCurrency || newAgentCurrencyList[0] })
    }

    setCurrencyList(newAgentCurrencyList)
  }, [raCurrency, fiatCurrencyData?.currencyList])

  useEffect(() => {}, [currentCurrency])

  useEffect(() => {
    setTotalAmount(calculateTotal())
  }, [currentCurrency?.currencyEnName, walletData, raCurrency])

  useEffect(() => {
    if (isLogin) {
      setMerchantInfo()
    }
  }, [isLogin])

  useUpdateEffect(() => {
    if (getIsSingleFaitMode()) return
    if (
      fiatCurrencyData?.currencyList?.some(fiatInfo => fiatInfo?.currencyEnName === currentCurrency?.currencyEnName)
    ) {
      onChangeUserCurrency()
    }
  }, [currentCurrency?.currencyEnName])

  useUnmount(() => {
    wsSpotAssetsUnSubscribe(coinWSCallBack)
  })

  return (
    <div className={styles['wallet-wrap']}>
      <div
        className="wallet-left"
        onClick={() => {
          if (getIsSingleFaitMode()) return
          setPopupVisible(!popupVisible)
        }}
      >
        <WalletCurrency
          isGameDetailsPage={isGameDetailsPage}
          setPopupVisible={setPopupVisible}
          currentCurrency={currentCurrency}
          popupVisible={popupVisible}
          currencyList={currencyList}
          totalAmount={totalAmount}
          walletData={walletData}
          raCurrency={raCurrency}
        />
      </div>
      <div
        className="wallet-right"
        onClick={() => {
          if (getIsDemoUser())
            return Message.error(t`features_assets_common_transfer_common_transfer_button_index_uvmnnkvi4e`)
          setVisible(true)
        }}
      >
        <span>{getDetermineWhetherMethods()}</span>
        {localeInfo?.currencyMode !== CurrencyModeErationEnum.multiCurrency && (
          <img src={currentCurrency?.currencyImg || `${oss_area_code_image_domain_address}BR.png`} alt="" />
        )}
      </div>

      {!hiddenMenus && (
        <div className="user-menu">
          <UserMenuNavigation />
        </div>
      )}
      {visible && (
        <DepositSidebarSetting
          visible={visible}
          setVisible={setVisible}
          paymentDealType={paymentMethod}
          paymentColor={paymentColor}
        />
      )}
    </div>
  )
}
