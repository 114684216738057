import { oss_svg_image_domain_address } from '@/constants/oss'
import { usePageContext } from '@/hooks/use-page-context'
import { link } from '@/helper/link'
import { getGameLobbyRoutePath, getSportsRoutePath } from '@/helper/route/game'
import { t } from '@lingui/macro'
import cs from 'classnames'
import useApiHeaderMenu from '@/hooks/features/layout'
import { useCommonStore } from '@/store/common'
import { GetUseSportsMenuMethods } from '@/constants/common'
import EntryButton, { IEntryButtonProps } from '../entry-button'
import styles from './index.module.css'

interface IContentEntriesProps {
  verticalMode?: boolean
}

function ContentEntries({ verticalMode }: IContentEntriesProps) {
  const { localeInfo } = useCommonStore()
  const pageContext = usePageContext()
  useApiHeaderMenu()
  const sportsPath = getSportsRoutePath()

  const isSports = pageContext.path.startsWith(sportsPath)
  const buttons: IEntryButtonProps[] = [
    {
      label: t`features_layout_content_compnent_layout_menu_content_entries_index_6vu0avp3ck`,
      bgImg: `${oss_svg_image_domain_address}layout/game_entry.png`,
      active: !isSports,
      className: 'game-entry-button',
      showTooltip: verticalMode,
      onClick: () => link(getGameLobbyRoutePath()),
    },
    {
      label: t`features_layout_content_compnent_layout_menu_content_entries_index_9lupdvvpnm`,
      bgImg: `${oss_svg_image_domain_address}layout/sports_entry_bg.png`,
      active: isSports,
      className: 'game-entry-button',
      showTooltip: verticalMode,
      onClick: () => {
        GetUseSportsMenuMethods(localeInfo?.showSports) && link(sportsPath)
      },
    },
  ]

  return (
    <div className={cs(styles['home-header-entries'], { 'is-vertical': verticalMode })}>
      {buttons.map((button, index) => (
        <EntryButton key={index} {...button} />
      ))}
    </div>
  )
}

export default ContentEntries
