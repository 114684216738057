import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { link } from '@/helper/link'
import { useUserStore } from '@/store/user'
import { useCommonStore } from '@/store/common'
import { EntertainmentArea } from '@/helper/route/entertainment'
import LayoutMenuCard from '@/features/recreation/theme/components/menu-card'
import { getGameGroupTypeList, postAllGameTypeList } from '@/apis/game'
import { AllGameTypeListResp, RaHallGroupTypeProps } from '@/typings/api/game'

import {
  getFavoriteRoutePath,
  getGameCategoriesRoutePath,
  getMyBetsRoutePath,
  getRecentGamesRoutePath,
  getSettingsRoutePath,
} from '@/helper/route/game'
import { useRaSettingsStore } from '@/store/settings'
import { WalletModeEnum } from '@/constants/settings'
import { commonMenuTypeEnum } from '@/constants/layout'
import { CommonMenuModal } from '@/features/recreation/component/common-menu-modal'
import { LayoutMenuProps } from './index'

type CommonlyProps = {
  onlyIcons?: boolean
  className?: string
}

export default function Commonly(props: CommonlyProps) {
  const [commonModalVisible, setCommonModalVisible] = useState<boolean>(false) // 常用弹窗
  const { isLogin } = useUserStore()
  const { commonMenu, setCommonMenu, localeInfo } = useCommonStore()
  const {
    userInfo: { uid },
  } = useUserStore()
  const newUid = uid || 'system'

  const {
    raSettings: { walletModeCd },
  } = useRaSettingsStore()

  // 游戏馆分类列表
  const [gameHallList, setGameHallList] = useState<RaHallGroupTypeProps[]>([])
  // 游戏分类列表
  const [gameList, setGameList] = useState<AllGameTypeListResp[]>([])

  const switchRouterData: LayoutMenuProps[] = [
    {
      isHide: walletModeCd === WalletModeEnum.single,
      isDisabled: !isLogin,
      name: t`features/assets/common/transfer/index-0`,
      id: commonMenuTypeEnum.fundTransfer,
      router: EntertainmentArea().quotaConversion,
      icon: 'icon_provider_sidebar_v2',
      isIcon: true,
    },
    {
      isDisabled: !isLogin,
      name: t`features_home_component_switch_router_index_qf1bqguow0`,
      id: commonMenuTypeEnum.myRebate,
      router: EntertainmentArea().myRebate,
      icon: 'icon_commission_sidebar_v2',
      isIcon: true,
    },
    {
      isHide: walletModeCd === WalletModeEnum.single,
      isDisabled: !isLogin,
      name: t`features/assets/main/financial-record/index-2`,
      id: commonMenuTypeEnum.transferRecord,
      router: EntertainmentArea().transferRecords,
      icon: 'icon_conversion_sidebar_v2',
      isIcon: true,
    },
    {
      isHide: !localeInfo?.home?.sidebar?.report,
      isDisabled: !isLogin,
      name: t`features_home_component_switch_router_index_55a8dpaxkh`,
      id: commonMenuTypeEnum.report,
      router: EntertainmentArea().reportRecords,
      icon: 'icon_report_statistics_sidebar_v2',
      isIcon: true,
    },
    {
      isDisabled: false,
      name: t`user.field.reuse_08`,
      id: commonMenuTypeEnum.setting,
      router: getSettingsRoutePath(),
      icon: 'user_anatar_site_icon_v2',
      isIcon: true,
    },
  ]

  const personGameMenu: LayoutMenuProps[] = [
    {
      isDisabled: !isLogin,
      id: commonMenuTypeEnum.favorite,
      name: t`features_layout_content_compnent_layout_menu_index_u2acn3tyb7`,
      icon: 'icon_collect_sidebar_pre_v2',
      router: getFavoriteRoutePath(),
      isIcon: true,
    },
    {
      isDisabled: !isLogin,
      id: commonMenuTypeEnum.recent,
      name: t`features_layout_content_compnent_layout_menu_index_t75oqijqri`,
      icon: 'icon_recent_game_sidebar_v2',
      router: getRecentGamesRoutePath(),
      isIcon: true,
    },
    {
      isDisabled: !isLogin,
      id: commonMenuTypeEnum.myBet,
      name: t`features_home_my_bet_index_avwmsadjdl`,
      icon: 'icon_bet_sidebar_v2',
      router: getMyBetsRoutePath(),
      isIcon: true,
    },
  ]

  /**
   * 查询游戏馆分类列表
   */
  const onLoadGameHallList = async () => {
    const res = await getGameGroupTypeList({})
    if (!res?.isOk || !res?.data) return

    setGameHallList(res?.data)
  }

  /**
   * 查询游戏类型列表
   */
  const onLoadGameList = async () => {
    const res = await postAllGameTypeList({})
    if (!res?.isOk || !res?.data) return

    setGameList(res?.data)
  }

  useEffect(() => {
    const newCommonMenuList = commonMenu[newUid]?.[walletModeCd] || []
    onLoadGameHallList()
    if (newCommonMenuList.length > 0) return
    setCommonMenu({ ...commonMenu, [newUid]: { ...commonMenu[newUid], ...[newUid], [walletModeCd]: personGameMenu } })
  }, [newUid, walletModeCd])

  useEffect(() => {
    isLogin && onLoadGameList()
  }, [isLogin])

  useEffect(() => {
    // 处理多语言问题
    const newCommonMenuList = commonMenu[newUid]?.[walletModeCd] || []
    newCommonMenuList.forEach(item => {
      let newItem = [...personGameMenu, ...switchRouterData, ...gameHallList, ...gameList].find(
        aItem => aItem.id === item.id
      )
      item.name = newItem?.name ?? item.name
    })
  }, [gameHallList, gameList])

  return (
    <>
      <LayoutMenuCard
        className={props.className}
        data={commonMenu[newUid]?.[walletModeCd] || []}
        isShowIcon={props.onlyIcons}
        title={props.onlyIcons ? '' : t`features/user/person-application/country-select/areaCode-0`}
        titleIcon="icon_cb_bianji_v2"
        onChange={v => {
          link(v?.router ? v?.router : getGameCategoriesRoutePath({ ...v }))
        }}
        onTitleIconClick={() => setCommonModalVisible(true)}
      />

      {commonModalVisible && (
        <CommonMenuModal
          visible={commonModalVisible}
          onClose={() => setCommonModalVisible(false)}
          uid={newUid}
          functionMenu={[...personGameMenu, ...switchRouterData]}
          gameHallList={gameHallList}
          gameList={gameList}
          notDelList={personGameMenu}
        />
      )}
    </>
  )
}
