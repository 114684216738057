import { useEffect, useState } from 'react'
import { Menu, Spin } from '@nbit/arco'
import { cloneDeep } from 'lodash'
import { link } from '@/helper/link'
import { getGamesRoutePath, getGameGroupRoutePath } from '@/helper/route/game'
import { RaHallGroupTypeProps } from '@/typings/api/game'
import { HallGameGroupTypeEnum } from '@/helper/game'
import { getGameTypeList, getGameTypeData } from '@/apis/game'
import { t } from '@lingui/macro'
import { loadedGameStatusEnum } from '@/constants/layout'
import styles from './index.module.css'

function GameList() {
  interface GameGroupItem extends RaHallGroupTypeProps {
    loadedStatus?: loadedGameStatusEnum
  }

  const [gameTypeData, setGameTypeData] = useState<GameGroupItem[]>([])

  const headerGroupType = async () => {
    const { data, isOk } = await getGameTypeList({
      sectionCd: 'top',
    })
    if (data && isOk) {
      const newData = data?.map(item => {
        return {
          ...item,
          submenu: [],
          isCheckout: false,
          loadedStatus: loadedGameStatusEnum.notLoaded,
        }
      })
      setGameTypeData(newData)
    }
  }

  const getGroupList = async id => {
    const params = {
      typeCd: id,
      showDirect: '1',
    }
    const { data, isOk } = await getGameTypeData(params)
    if (isOk && data) {
      const newData = cloneDeep(gameTypeData)
      const handleData = newData?.map(item => {
        if (item?.id === Number(id)) {
          return {
            ...item,
            submenu: data?.list,
            loadedStatus: loadedGameStatusEnum.loaded,
          }
        }

        return {
          ...item,
        }
      })
      setGameTypeData(handleData)
    }
  }

  const linkToGame = route => {
    if (route?.direct === HallGameGroupTypeEnum.yes) {
      return link(
        route?.id ? getGamesRoutePath(route?.id, route?.groupId) : getGamesRoutePath(route?.id ?? route?.groupId)
      )
    }

    if (route?.direct === HallGameGroupTypeEnum.no) {
      return link(route?.id ? getGamesRoutePath(route?.id, route?.groupId) : getGameGroupRoutePath(route))
    }

    return link(getGameGroupRoutePath(route))
  }

  const subMenuClick = (key: string) => {
    const clickItem = gameTypeData.find(item => item.id === Number(key))
    if (clickItem) {
      if (clickItem.loadedStatus !== loadedGameStatusEnum.notLoaded) return
      const newData = cloneDeep(gameTypeData)
      const newItem = newData.find(item => item.id === Number(key))
      if (newItem) {
        newItem.loadedStatus = loadedGameStatusEnum.loading
        setGameTypeData(newData)
      }

      getGroupList(key)
    }
  }

  useEffect(() => {
    headerGroupType()
  }, [])

  return (
    <div className={styles.scoped}>
      <div className="list-box game-list">
        <Menu className="menu" selectable={false} onClickSubMenu={subMenuClick}>
          {gameTypeData.map(item => {
            return (
              <Menu.SubMenu className="sub-menu" key={`${item.id}`} title={item.name}>
                {item.submenu && item.submenu.length === 0 ? (
                  item.loadedStatus === loadedGameStatusEnum.loading ? (
                    <Spin className="loading" loading />
                  ) : (
                    <div className="no-data">{t`features_c2c_center_coin_select_search_index_sjh06hnifczlz6ix00rtq`}</div>
                  )
                ) : (
                  (item.submenu || []).map(subItem => {
                    return (
                      <Menu.Item key={`${subItem.id}`}>
                        <div onClick={() => linkToGame(subItem)}>{subItem.name || subItem.groupName}</div>
                      </Menu.Item>
                    )
                  })
                )}
              </Menu.SubMenu>
            )
          })}
        </Menu>
      </div>
    </div>
  )
}

export default GameList
