/**
 * 欢迎页
 */
import { t } from '@lingui/macro'
import { memo, useEffect, useState } from 'react'
import { usePageContext } from '@/hooks/use-page-context'
import { getOssImageUrl } from '@/helper/common'
import classNames from 'classnames'
import { useCommonStore } from '@/store/common'
import styles from './index.module.css'
import { welcomeLocaleData } from './welcome_locale_config'

function WelcomeLayout() {
  const pageContext = usePageContext()
  const { locale, isWelcomeHomeShow, setIsWelcomeHomeShow } = useCommonStore()
  const { locale: localSearch } = pageContext?.urlParsed?.search || {}

  const getLanguageConfig = _locale => {
    return welcomeLocaleData[_locale]
  }

  const getLanguageByKey = key => {
    if (!localSearch || localSearch === locale) {
      return {
        'features_welcome_index_qbavhqn5lu': t`features_welcome_index_qbavhqn5lu`,
        'future.funding-history.index-price.ingredient.column.exchange': t`future.funding-history.index-price.ingredient.column.exchange`,
      }[key]
    }
    return getLanguageConfig(localSearch)[key]
  }

  useEffect(() => {
    if (isWelcomeHomeShow) {
      const timer = setTimeout(() => {
        setIsWelcomeHomeShow(false)
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [])

  if (!isWelcomeHomeShow) return null

  return (
    <div className={classNames(styles['welcome-root'], 'fade-out')}>
      <div className="welcome-main">
        <div className="welcome-desc">
          <div>{getLanguageByKey('features_welcome_index_qbavhqn5lu')}</div>
          <div className="name">
            {getLanguageByKey('future.funding-history.index-price.ingredient.column.exchange')}
          </div>
        </div>
        <div>
          <img className="welcome-image" src={getOssImageUrl('welcome_img', true)} alt="" />
        </div>
      </div>
    </div>
  )
}

export default memo(WelcomeLayout)
