/**
 * 常用 - 弹窗
 */

import Icon from '@/components/icon'
import { Button, Divider, Input, Message, Modal } from '@nbit/arco'
import { useState } from 'react'
import { t } from '@lingui/macro'
import { commonModalTabEnum, getCommonModalTabName } from '@/constants/layout'
import { useCommonStore } from '@/store/common'
import classNames from 'classnames'
import { useRaSettingsStore } from '@/store/settings'
import { AllGameTypeListResp, RaHallGroupTypeProps } from '@/typings/api/game'
import NoDataImage from '@/components/no-data-image'
import { Type } from '@/components/lazy-image'
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc'
import { LayoutMenuProps } from '@/features/layout/content/compnent/layout-menu/menu-main'
import RecordTabs from '@/features/recreation/theme/components/record-tabs'
import styles from './index.module.css'

interface ICommonMenuModalProps {
  /** 是否展示 */
  visible: boolean
  /** 用户 uid(未登录时为 system) */
  uid: string
  /** 功能菜单 */
  functionMenu: LayoutMenuProps[]
  // 游戏馆分类列表
  gameHallList: RaHallGroupTypeProps[]
  // 游戏分类列表
  gameList: AllGameTypeListResp[]
  /** 不支持删除常用列表 */
  notDelList: LayoutMenuProps[]
  /** 关闭 */
  onClose: () => void
}

function CommonMenuModal(props: ICommonMenuModalProps) {
  const { visible, uid, functionMenu, notDelList, onClose } = props || {}
  const { commonMenu, setCommonMenu } = useCommonStore()
  const {
    raSettings: { walletModeCd },
  } = useRaSettingsStore()
  const tabs = Object.keys(commonModalTabEnum).map(key => ({
    name: getCommonModalTabName(key),
    id: key,
  }))
  const [searchKey, setSearchKey] = useState('')
  const [currentTab, setCurrentTab] = useState<string>(commonModalTabEnum.function)
  const [addedList, setAddedList] = useState(commonMenu[uid]?.[walletModeCd])

  const onGetMenuList = () => {
    if (searchKey) {
      const allList = [...functionMenu.filter(item => !item.isHide), ...props.gameHallList, ...props.gameList]
      return allList.filter(item => item?.name?.toLowerCase().includes(searchKey.toLowerCase()))
    }

    return (
      {
        [commonModalTabEnum.function]: functionMenu.filter(item => !item.isHide),
        [commonModalTabEnum.venueType]: props.gameHallList,
        [commonModalTabEnum.gameType]: props.gameList,
        // [commonModalTabEnum.venueOrGame]: [...props.gameHallList, ...props.gameList],
      }[currentTab] || []
    )
  }

  /**
   * 添加常用菜单
   */
  const addCommonMenu = menuInfo => {
    if (addedList.length >= 10) {
      Message.error(
        t({
          id: `features_layout_content_compnent_common_menu_modal_indext_ltbzb9jewj`,
          values: { 0: 10 },
        })
      )
      return
    }

    setAddedList([...addedList, menuInfo])
  }

  /**
   * 删除常用菜单
   */
  const delCommonMenu = (id: string | number) => {
    setAddedList(addedList.filter(item => item.id !== id))
  }

  /**
   * 确定
   */
  const onCommit = () => {
    setCommonMenu({ ...commonMenu, [uid]: { ...commonMenu[uid], ...[uid], [walletModeCd]: addedList } })
    onClose()
  }

  const DragHandle = SortableHandle(() => <Icon name="icon_drag" className="drag-icon" />)

  const DragContainers: any = SortableContainer(({ children }) => {
    return <div className="added-wrap">{children}</div>
  })

  const DragItem: any = SortableElement(({ addedMenu, index, isNotDel }) => (
    <div className="added-cell moving-z-1003" key={index}>
      <div className="info-wrap">
        <DragHandle />
        <span className="added-name">{addedMenu?.name}</span>
      </div>
      {!isNotDel && (
        <Icon
          name="icon_remove"
          className="added-del"
          onClick={e => {
            e.stopPropagation()
            delCommonMenu(addedMenu?.id)
          }}
        />
      )}
    </div>
  ))

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAddedList(arrayMove([...addedList], oldIndex, newIndex))
  }

  return (
    <Modal
      visible={visible}
      closable={false}
      footer={null}
      className={styles['common-menu-modal-root']}
      onCancel={onClose}
    >
      <div className="modal-header">
        <label className="title">{t`features/user/person-application/country-select/areaCode-0`}</label>
        <Icon name="icon_delete" className="close-icon" onClick={onClose} />
      </div>

      <Input
        placeholder={t`help.center.support_02`}
        value={searchKey}
        onChange={setSearchKey}
        className={styles['modal-search-input']}
        prefix={<Icon name="icon_search" className="text-text_color_03" />}
      />

      {!searchKey && (
        <RecordTabs
          isShowStealth={false}
          value={currentTab}
          tabs={tabs}
          onChange={setCurrentTab}
          className="modal-tabs"
        />
      )}

      <div className="menus-wrap">
        {onGetMenuList()?.length > 0 ? (
          onGetMenuList()?.map((menuInfo, i: number) => {
            // 是否已经包含了该菜单
            const isSome = addedList?.some(addedMenu => addedMenu?.id === menuInfo?.id)
            // 是否不支持删除该菜单
            const isNotDel = notDelList?.some(item => item?.id === menuInfo?.id)
            return (
              <div className="menu-cell" key={i}>
                <span>{searchKey ? menuInfo?.name : `${i + 1}、${menuInfo?.name}`}</span>
                {!isNotDel && (
                  <Icon
                    name={isSome ? 'icon_remove' : 'icon_add_to'}
                    className={classNames('menu-icon', {
                      add: !isSome,
                      del: isSome,
                    })}
                    onClick={() => (isSome ? delCommonMenu(menuInfo?.id) : addCommonMenu(menuInfo))}
                  />
                )}
              </div>
            )
          })
        ) : (
          <NoDataImage whetherManyBusiness={false} type={Type.png} name={'image_default_graph'} className="mb-4" />
        )}
      </div>

      <Divider className="modal-divider" />
      <div className="modal-bottom">
        <div className="added-header">
          <span className="added-title">{t`features_layout_content_compnent_common_menu_modal_indext_3hkuxshuse`}</span>
          <span className="added-desc">
            {t({
              id: `features_layout_content_compnent_common_menu_modal_indext_ltbzb9jewj`,
              values: { 0: 10 },
            })}
          </span>
        </div>

        <DragContainers lockAxis={'y'} lockOffset={0} pressDelay={0} onSortEnd={onSortEnd} useDragHandle>
          {addedList?.map((addedMenu, i: number) => {
            const isNotDel = notDelList.some(item => item?.id === addedMenu?.id)
            return <DragItem key={addedMenu?.id} addedMenu={addedMenu} index={i} isNotDel={isNotDel} />
          })}
        </DragContainers>

        <div className="btn-wrap">
          <Button className="modal-btn cancel" onClick={onClose}>
            {t`trade.c2c.cancel`}
          </Button>
          <Button className="modal-btn commit" type="primary" onClick={onCommit}>
            {t`user.field.reuse_17`}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export { CommonMenuModal }
