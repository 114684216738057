import { t } from '@lingui/macro'
import { useEffect, useMemo, useState } from 'react'
import { Message } from '@nbit/arco'
import { useMount, useRequest, useUpdateEffect } from 'ahooks'
import { link } from '@/helper/link'
import { useUserStore } from '@/store/user'
import { getGameTypeList } from '@/apis/game'
import { useCommonStore } from '@/store/common'
import { GameTypeEnum } from '@/helper/game'
import { EntertainmentArea } from '@/helper/route/entertainment'
import LayoutMenuCard from '@/features/recreation/theme/components/menu-card'
import Icon from '@/components/icon'
import { oss_svg_image_domain_address } from '@/constants/oss'
import classNames from 'classnames'
import { getGameCategoriesRoutePath, getGameSuppliersRoutePath, getSettingsRoutePath } from '@/helper/route/game'
import { useRaSettingsStore } from '@/store/settings'
import { WalletModeEnum } from '@/constants/settings'
import { commonMenuTypeEnum } from '@/constants/layout'
import { getMemberUserLoginOut } from '@/apis/user'
import { removeToken } from '@/helper/auth'
import { removeC2CParamsTipsCache } from '@/helper/cache'
import { ModuleEnum } from '@/constants/module-config'
import { getModuleStatusByKey } from '@/helper/module-config'
import { getSettingsTab } from '@/helper/settings'
import { getIsDemoUser } from '@/helper/user'
import { getUserInfoMenus } from '@/helper/layout/menu'
import { ThemeEnum } from '@/constants/base'
import { GetUseGameSuppliersMethods, showLocaleInfoContent } from '@/constants/common'
import styles from './index.module.css'
import Commonly from './commonly'
import ContentEntries from '../content-entries'

export type LayoutMenuProps = {
  /** 是否不可点击 */
  isDisabled?: boolean
  // value: string
  name: string
  icon: string
  /** 跳转路由 */
  router?: string
  /** 图标类型是否 iconfont */
  isIcon: boolean
  /** 是否展开收起 */
  isHide?: boolean
  /** 是否有打开箭头 */
  isShowDown?: boolean
  /** 根据箭头显示隐藏 */
  downShow?: boolean
  /** 箭头默认显示关闭 */
  iconUp?: boolean
  /** 是否有打开箭头 */
  onclick?: () => void
  id: string
  /** 是否隐藏菜单项 */
  isHideMenu?: boolean
}

type MenuMainProps = {
  onlyIcons?: boolean
  triggerOpen?: () => void
}

export default function MenuMain(props: MenuMainProps) {
  const { isLogin } = useUserStore()
  const { menuGameList, setMenuGameList, theme, localeInfo } = useCommonStore()

  const {
    raSettings: { walletModeCd },
  } = useRaSettingsStore()
  const homeBtnBg = `${oss_svg_image_domain_address}bg_home_button.png`
  const homeIconBg = `${oss_svg_image_domain_address}icon_home.png`
  const isDark = theme === ThemeEnum.dark

  const gameSupplierMenu: LayoutMenuProps[] = [
    {
      id: commonMenuTypeEnum.gameSupplier,
      name: t`features_layout_content_compnent_layout_menu_index_00f9dlrqie`,
      icon: 'icon_games_bjl_sidebar_v2',
      router: getGameSuppliersRoutePath(),
      isIcon: true,
    },
  ]

  const [switchRouterData, setSwitchRouterData] = useState<LayoutMenuProps[]>([])
  const [userInfoMenus, setUserInfoMenus] = useState<LayoutMenuProps[]>([])

  /** 获取游戏列表 */
  const getGameTypeData = async () => {
    const params = {
      sectionCd: GameTypeEnum.menu,
    }
    const { isOk, data } = await getGameTypeList(params)
    if (isOk && data) {
      setMenuGameList(data)
    }
  }

  const { run } = useRequest(getGameTypeData, { manual: true })

  // useMount(() => {
  //   run()
  // })

  useEffect(() => {
    run()
  }, [walletModeCd])

  useEffect(() => {
    const data: LayoutMenuProps[] = [
      // {
      //   isDisabled: !isLogin,
      //   name: t`features/user/personal-center/profile/index-9`,
      //   id: 'user',
      //   router: '',
      //   icon: 'user_head',
      //   isIcon: true,
      //   isShowDown: true,
      //   iconUp: false,
      // },
      // {
      //   isDisabled: !isLogin,
      //   icon: 'icon_personal_public_vip',
      //   name: t`features_user_personal_center_menu_navigation_index_kgmvkfruho`,
      //   router: getVipCenterRoutePath(),
      //   id: ModuleEnum.vip,
      //   isIcon: true,
      //   downShow: true,
      //   isHide: true,
      // },
      // {
      //   isDisabled: !isLogin,
      //   icon: 'user_down_security',
      //   name: t`user.personal_center_09`,
      //   router: '/personal-center/account-security',
      //   id: 'security',
      //   isIcon: true,
      //   downShow: true,
      //   isHide: true,
      // },
      // {
      //   isDisabled: !isLogin,
      //   icon: 'icon_personal_public_gift',
      //   name: t`user.personal_center_05`,
      //   router: '/agent',
      //   id: 'public',
      //   isIcon: true,
      //   downShow: true,
      //   isHide: true,
      // },
      // {
      //   isDisabled: !isLogin,
      //   icon: 'icon_alliance_center',
      //   name: t`modules_agent_agency_center_index_page_jyovg1cnix`,
      //   router: '/agent/agency-center',
      //   id: 'alliance',
      //   isIcon: true,
      //   downShow: true,
      //   isHide: true,
      // },
      // {
      //   isDisabled: !isLogin,
      //   icon: 'icon_personal_public_ticket',
      //   name: t`features_welfare_center_index_vg4pz_d6jn`,
      //   router: '/welfare-center',
      //   id: 'welfare',
      //   isIcon: true,
      //   downShow: true,
      //   isHide: true,
      // },
      // {
      //   isDisabled: !isLogin,
      //   icon: 'a-icon_personal_public_api_whitei',
      //   name: t`features_user_personal_center_settings_api_index_5101354`,
      //   router: '/personal-center/settings/api',
      //   id: 'settingsApi',
      //   isIcon: true,
      //   downShow: true,
      //   isHide: true,
      // },
      // {
      //   isDisabled: !isLogin,
      //   icon: 'user_anatar_site_icon',
      //   name: t`features_trade_trade_setting_index_2510`,
      //   router: '/personal-center/settings',
      //   id: 'user_anatarSettings',
      //   isIcon: true,
      //   downShow: true,
      //   isHide: true,
      // },
      // {
      //   isDisabled: !isLogin,
      //   icon: 'icon_user_quit',
      //   name: t`features_layout_content_compnent_layout_menu_menu_main_index_s8lflz8r9z`,
      //   onclick: () => {
      //     handleLoginOut()
      //   },
      //   id: 'settings',
      //   isIcon: true,
      //   downShow: true,
      //   isHide: true,
      // },
      {
        isHide: walletModeCd === WalletModeEnum.single,
        isDisabled: !isLogin,
        name: t`features/assets/common/transfer/index-0`,
        id: commonMenuTypeEnum.fundTransfer,
        router: EntertainmentArea().quotaConversion,
        icon: 'icon_provider_sidebar_v2',
        isIcon: true,
      },
      {
        isDisabled: !isLogin,
        name: t`features_home_component_switch_router_index_qf1bqguow0`,
        id: commonMenuTypeEnum.myRebate,
        router: EntertainmentArea().myRebate,
        icon: 'icon_commission_sidebar_v2',
        isIcon: true,
      },
      {
        isHide: walletModeCd === WalletModeEnum.single,
        isDisabled: !isLogin,
        name: t`features/assets/main/financial-record/index-2`,
        id: commonMenuTypeEnum.transferRecord,
        router: EntertainmentArea().transferRecords,
        icon: 'icon_conversion_sidebar_v2',
        isIcon: true,
      },
      {
        isHide: !showLocaleInfoContent(localeInfo?.home?.sidebar?.report),
        isDisabled: !isLogin,
        name: t`features_home_component_switch_router_index_55a8dpaxkh`,
        id: commonMenuTypeEnum.report,
        router: EntertainmentArea().reportRecords,
        icon: 'icon_report_statistics_sidebar_v2',
        isIcon: true,
      },
      {
        isDisabled: getSettingsTab(isLogin).length === 0,
        name: t`user.field.reuse_08`,
        id: commonMenuTypeEnum.setting,
        router: getSettingsRoutePath(),
        icon: 'user_anatar_site_icon_v2',
        isIcon: true,
      },
    ]

    const vipShow = getModuleStatusByKey(ModuleEnum.vip)
    const updatedData = data
      .map(item => {
        if (item.id === ModuleEnum.vip) {
          return {
            ...item,
            isHide: vipShow,
          }
        }
        return item
      })
      .filter(e => !e.isHideMenu)

    setSwitchRouterData(updatedData)
  }, [isLogin, walletModeCd, localeInfo])

  useEffect(() => {
    setUserInfoMenus(getUserInfoMenus(isLogin, getIsDemoUser(), handleLoginOut))
  }, [isLogin, getIsDemoUser()])

  const store = useUserStore()
  const handleLoginOut = async () => {
    const res = await getMemberUserLoginOut({})
    res.isOk && Message.success(t`features_user_personal_center_menu_navigation_index_2443`)
    store.removeUserInfo()
    store.removeC2cModeUserInfo()
    await removeToken()
    removeC2CParamsTipsCache()
    link('/')
  }

  return (
    <div className={styles['layout-menu-wrap']}>
      <div className={`layout-menu-content ${props.onlyIcons ? 'layout-menu-content-hidden' : ''}`}>
        <div className={classNames('fixed-top', { 'only-icon': props.onlyIcons }, { 'is-dark': isDark })}>
          <div className="menu-header-wrap">
            <div className="menu-header-icon" onClick={() => props?.triggerOpen && props.triggerOpen()}>
              <Icon name="nav_icon_liulang_v2" className="retract-icon" />
            </div>
            <ContentEntries verticalMode={props.onlyIcons} />
            {/* {!props.onlyIcons && (
              <ContentEntries />
              // <div
              //   style={{ backgroundImage: `url("${homeBtnBg || ''}")` }}
              //   className="menu-home-btn"
              //   onClick={() => link(getGameLobbyRoutePath())}
              // >
              //   <LazyImage src={`${oss_svg_image_domain_address}icon_home_button.png`} className="menu-home-icon" />
              //   <label className="menu-home-text">{t`features_home_component_switch_router_index_zcydywzb7d`}</label>
              // </div>
            )} */}
          </div>

          {/* {props.onlyIcons && (
            <div
              className="menu-home-cell"
              style={{ backgroundImage: `url("${homeIconBg || ''}")` }}
              onClick={() => link(getGameLobbyRoutePath())}
            />
          )} */}
        </div>

        <div className="scroll-wrap">
          {/* <div className="menu-wallet-balance">
          <div className={`wallet-balance-show`}>
            <Icon name="icon_wallet_sidebar" className="wallet-icon" />
            <label>{'钱包余额'}</label>
            <Icon name="icon_eyes" className="wallet-show-icon" />
          </div>
          <div className={`wallet-balance-footer ${isShow ? 'wallet-balance-footer-hidden' : ''}`}>
            <div className="wallet-left">
              <label>{`1,234.14 ${coinId}`}</label>
              <Trigger
                position="bottom"
                trigger={'click'}
                popup={() => (
                  <div className={styles['trigger-layout-menu-wrap']}>
                    {layoutCoinList?.map(item => {
                      return (
                        <div
                          key={item}
                          className={`items ${item === coinId ? 'active-item' : ''}`}
                          onClick={() => setCoinId(item)}
                        >
                          {item}
                        </div>
                      )
                    })}
                  </div>
                )}
              >
                <Icon name="icon_arrow_down" className="wallet-left-icon" />
              </Trigger>
            </div>
            <div className="wallet-right">{'充值'}</div>
          </div>
        </div> */}

          <Commonly onlyIcons={props.onlyIcons} className="game-menu-card common-menu" />

          {menuGameList?.length ? (
            <LayoutMenuCard
              className="game-menu-card"
              data={menuGameList}
              isShowIcon={props.onlyIcons}
              title={props.onlyIcons ? '' : t`features_layout_content_compnent_layout_menu_index_wcj07cbmvk`}
              onChange={v => {
                const currentHref = decodeURIComponent(location.href)
                const targetPath = getGameCategoriesRoutePath({ ...v })
                // 防止重复点击
                if (currentHref.endsWith(targetPath)) return
                link(targetPath)
              }}
            />
          ) : null}
          <LayoutMenuCard
            className="game-menu-card"
            isShowIcon={props.onlyIcons}
            data={GetUseGameSuppliersMethods(localeInfo?.home?.sidebar?.provider) ? gameSupplierMenu : []}
            onChange={v => link(v?.router)}
          />
          <LayoutMenuCard
            className="game-menu-card"
            isShowIcon={props.onlyIcons}
            data={userInfoMenus}
            onChange={v => {
              if (v.onclick) {
                v.onclick()
                return
              }
              // TODO: 子菜单收起、展开逻辑，目前来看只支持一个子菜单，子菜单跟父级菜单没有建立关联
              if (v.isShowDown) {
                const updatedData = userInfoMenus.map(item => {
                  if (item.downShow) {
                    return { ...item, isHide: !item.isHide }
                  }
                  if (item.isShowDown) {
                    return { ...item, iconUp: !item.iconUp }
                  }
                  return item
                })
                setUserInfoMenus(updatedData)
                return
              }
              link(v?.router)
            }}
          />
          <LayoutMenuCard
            className="game-menu-card"
            isShowIcon={props.onlyIcons}
            data={switchRouterData}
            onChange={v => {
              if (v.onclick) {
                v.onclick()
                return
              }
              if (v.isShowDown) {
                const updatedData = switchRouterData.map(item => {
                  if (item.downShow) {
                    return { ...item, isHide: !item.isHide }
                  }
                  if (item.isShowDown) {
                    return { ...item, iconUp: !item.iconUp }
                  }
                  return item
                })
                setSwitchRouterData(updatedData)
                return
              }
              link(v?.router)
            }}
          />
        </div>
      </div>
    </div>
  )
}
