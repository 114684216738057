import Icon from '@/components/icon'
import { useState, useEffect } from 'react'
import { Trigger } from '@nbit/arco'
import { link } from '@/helper/link'
import { getUserBalance } from '@/apis/entertainment-area'
import { usePersonalCenterStore } from '@/store/user/personal-center'
import { useMemoizedFn, useUnmount, useUpdateEffect } from 'ahooks'
import { formatCurrency } from '@/helper/decimal'
import { rateFilter } from '@/helper/assets'
import { useAssetsStore } from '@/store/assets'
import { Asset_Body } from '@/plugins/ws/protobuf/ts/proto/Asset'
import { useLayoutStore } from '@/store/layout'
import styles from './index.module.css'

export interface ICurrency {
  currencyEnName: string
  offset: number
}

export default function Wallet() {
  const [walletData, setWalletData] = useState<number>(0)
  const { fetchCoinRate, wsSpotAssetsSubscribe, wsSpotAssetsUnSubscribe } = useAssetsStore()
  const { fiatCurrencyData, getFiatCurrencyData } = usePersonalCenterStore()
  const [currencyList, setCurrencyList] = useState<ICurrency[]>([]) // 法币 + 结算币种列表
  const [raCurrency, setRaCurrency] = useState<ICurrency>({} as ICurrency) // 当前结算币种
  const [currentCurrency, setCurrentCurrency] = useState<ICurrency>({} as ICurrency) // 当前选择法币/结算币种
  const [totalAmount, setTotalAmount] = useState('') // 换算后的总额
  const [popupVisible, setPopupVisible] = useState(false)
  const { postMessageAmount, setBalanceInfo } = useLayoutStore()

  const getWalletData = async () => {
    const { data, isOk } = await getUserBalance({
      ignoreGroup: true,
    })
    if (data && isOk) {
      setWalletData(data?.walletBalance as number)
      setRaCurrency({
        currencyEnName: data?.walletBalanceCoin || '',
        offset: data?.walletAccuracy || 2,
      })
    }
  }

  /**
   * 根据当前选中法币计算总额
   */
  const calculateTotal = () => {
    if (!walletData) return formatCurrency(walletData || 0, currentCurrency?.offset || 2)

    if (raCurrency?.currencyEnName === currentCurrency?.currencyEnName)
      return formatCurrency(walletData, currentCurrency?.offset || 2)

    return rateFilter({
      symbol: currentCurrency?.currencyEnName,
      rate: currentCurrency?.currencyEnName,
      amount: walletData,
      showUnit: false,
      isFormat: true,
    })
  }

  const coinWSCallBack = useMemoizedFn((data: Asset_Body[]) => {
    if (!data || data?.length === 0) return
    getWalletData()
  })

  const onWalletChange = () => {
    link('/assets/main/deposit')
  }

  // 游戏动画延迟 获取用户余额
  useEffect(() => {
    fetchCoinRate()
    getWalletData()
    getFiatCurrencyData()
    if (postMessageAmount) return
    wsSpotAssetsSubscribe(coinWSCallBack)
  }, [])

  // 监听 postmessage 游戏时的值
  useEffect(() => {
    postMessageAmount && setTotalAmount(postMessageAmount)
  }, [postMessageAmount])

  useUpdateEffect(() => {
    if (!raCurrency?.currencyEnName) return

    let settlementCurrency: ICurrency[] = [{ ...raCurrency }]
    const memberCurrency: ICurrency[] =
      fiatCurrencyData?.currencyList.map(item => {
        return {
          currencyEnName: item?.currencyEnName || '',
          offset: 2,
        }
      }) || []

    const isRepeat = memberCurrency?.some(item => item.currencyEnName === raCurrency?.currencyEnName)
    const newAgentCurrencyList = isRepeat ? memberCurrency : [...settlementCurrency, ...memberCurrency]

    if (
      !currentCurrency?.currencyEnName ||
      !newAgentCurrencyList?.some(item => item.currencyEnName === currentCurrency?.currencyEnName)
    ) {
      setCurrentCurrency(newAgentCurrencyList[0])
    }
    setCurrencyList(newAgentCurrencyList)
  }, [raCurrency, fiatCurrencyData?.currencyList])

  useUpdateEffect(() => {
    setTotalAmount(calculateTotal())
  }, [currentCurrency?.currencyEnName])

  useUpdateEffect(() => {
    if (postMessageAmount) return
    setTotalAmount(calculateTotal())
  }, [currentCurrency?.currencyEnName, walletData])

  useUnmount(() => {
    wsSpotAssetsUnSubscribe(coinWSCallBack)
  })

  useEffect(() => {
    raCurrency &&
      currentCurrency &&
      setBalanceInfo({
        raCurrency,
        currentCurrency,
      })
  }, [raCurrency, currentCurrency])

  return (
    <div className={styles['wallet-wrap']}>
      <div className="wallet-left">
        <label>{`${totalAmount} ${currentCurrency?.currencyEnName || '--'}`}</label>
        <Trigger
          popupVisible={popupVisible}
          position="br"
          trigger={'click'}
          popupAlign={{ bottom: [50, 16] }}
          popup={() => (
            <div className={styles['trigger-layout-menu-wrap']}>
              {currencyList?.map(item => {
                return (
                  <div
                    key={item?.currencyEnName}
                    className={`items ${item?.currencyEnName === currentCurrency?.currencyEnName ? 'active-item' : ''}`}
                    onClick={() => {
                      setCurrentCurrency(item)
                      setPopupVisible(false)
                    }}
                  >
                    {item?.currencyEnName}
                  </div>
                )
              })}
            </div>
          )}
          onVisibleChange={setPopupVisible}
        >
          <Icon
            name={popupVisible ? 'icon_arrow_up' : 'icon_arrow_down'}
            className="wallet-left-icon text-icon_color_03"
            onClick={() => setPopupVisible(true)}
          />
        </Trigger>
      </div>
      <div className="wallet-right" onClick={() => onWalletChange()}>
        <Icon name="icon_wallet_sidebar" className="wallet-icon" />
      </div>
    </div>
  )
}
