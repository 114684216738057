import { useLayoutStore } from '@/store/layout'
import { useGuidePageInfo } from '@/hooks/features/layout'
import { getHomePageConfig } from '@/helper/layout'
import ShouldGuidePageComponentDisplay from '@/features/home/common/component-should-display'
import FooterMenu from '@/features/recreation/component/footer-menu'
import FooterIcons from '@/features/recreation/component/footer-icons'
import Styles from './index.module.css'

function Footer(props) {
  const { contactUsData, bootomNode, className } = props
  const { footerData } = useLayoutStore()

  const { groupConfigDatas, columnsDatas, businessName } = footerData || {} // useFooter(data)
  const allMenus = [...(columnsDatas || []), contactUsData]

  const guidePage = useGuidePageInfo()
  const formattedLandingPageSection = getHomePageConfig(guidePage)
  const { pageInfoServiceSupport: footerConfig } = formattedLandingPageSection || {}

  return (
    <ShouldGuidePageComponentDisplay {...footerConfig}>
      <div className={className}>
        <div className="footer-wrap">
          <div className="footer-logo">
            <span>{businessName}</span>
            <FooterIcons data={groupConfigDatas} />
          </div>
          <FooterMenu data={allMenus} />
        </div>
        {bootomNode}
      </div>
    </ShouldGuidePageComponentDisplay>
  )
}

export default Footer
