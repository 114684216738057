import MenuCard2z from '@/features/recreation/theme/2z/menu-card'
import MenuCardNc from '@/features/recreation/theme/ok/menu-card'
import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'

export default function LayoutMenuCard(props) {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return <MenuCard2z {...props} />
    case InitThemeColor.ok:
      return <MenuCardNc {...props} />
    default:
      return <MenuCard2z {...props} />
  }
}
