import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { useMount, useRequest, useUpdateEffect } from 'ahooks'
import { link } from '@/helper/link'
import { useUserStore } from '@/store/user'
import { getGameTypeList } from '@/apis/game'
import { useCommonStore } from '@/store/common'
import { GameTypeEnum } from '@/helper/game'
import { EntertainmentArea } from '@/helper/route/entertainment'
import Icon from '@/components/icon'
import { oss_svg_image_domain_address } from '@/constants/oss'
import classNames from 'classnames'
import LazyImage from '@/components/lazy-image'
import {
  getGameCategoriesRoutePath,
  getGameLobbyRoutePath,
  getGameSuppliersRoutePath,
  getSettingsRoutePath,
} from '@/helper/route/game'
import { useRaSettingsStore } from '@/store/settings'
import { WalletModeEnum } from '@/constants/settings'
import { commonMenuTypeEnum } from '@/constants/layout'
import LayoutMenuCard from '@/features/recreation/theme/components/menu-card'
import Commonly from '@/features/recreation/theme/ok/layout/content/compnent/layout-menu/menu-main/commonly'
import styles from './index.module.css'

export type LayoutMenuProps = {
  /** 是否不可点击 */
  isDisabled?: boolean
  // value: string
  name: string
  icon: string
  /** 跳转路由 */
  router: string
  /** 图标类型是否 iconfont */
  isIcon: boolean
  /** 是否隐藏 */
  isHide?: boolean
  id: string
}

type MenuMainProps = {
  onlyIcons?: boolean
  triggerOpen?: () => void
}

export default function MenuMain(props: MenuMainProps) {
  const { isLogin } = useUserStore()
  const { menuGameList, setMenuGameList } = useCommonStore()

  const {
    raSettings: { walletModeCd },
  } = useRaSettingsStore()
  const homeBtnBg = `${oss_svg_image_domain_address}bg_home_button.png`
  const homeIconBg = `${oss_svg_image_domain_address}icon_home.png`

  const gameSupplierMenu: LayoutMenuProps[] = [
    {
      id: commonMenuTypeEnum.gameSupplier,
      name: t`features_layout_content_compnent_layout_menu_index_00f9dlrqie`,
      icon: 'icon_games_21_sidebar',
      router: getGameSuppliersRoutePath(),
      isIcon: true,
    },
  ]

  const [switchRouterData, setSwitchRouterData] = useState<LayoutMenuProps[]>([])

  /** 获取游戏列表 */
  const getGameTypeData = async () => {
    const params = {
      sectionCd: GameTypeEnum.menu,
    }
    const { isOk, data } = await getGameTypeList(params)
    if (isOk && data) {
      setMenuGameList(data)
    }
  }

  const { run } = useRequest(getGameTypeData, { manual: true })

  // useMount(() => {
  //   run()
  // })

  useEffect(() => {
    run()
  }, [walletModeCd])

  useEffect(() => {
    const data: LayoutMenuProps[] = [
      {
        isHide: walletModeCd === WalletModeEnum.single,
        isDisabled: !isLogin,
        name: t`features/assets/common/transfer/index-0`,
        id: commonMenuTypeEnum.fundTransfer,
        router: EntertainmentArea().quotaConversion,
        icon: 'icon_provider_sidebar',
        isIcon: true,
      },
      {
        isDisabled: !isLogin,
        name: t`features_home_component_switch_router_index_qf1bqguow0`,
        id: commonMenuTypeEnum.myRebate,
        router: EntertainmentArea().myRebate,
        icon: 'icon_commission_sidebar',
        isIcon: true,
      },
      {
        isHide: walletModeCd === WalletModeEnum.single,
        isDisabled: !isLogin,
        name: t`features/assets/main/financial-record/index-2`,
        id: commonMenuTypeEnum.transferRecord,
        router: EntertainmentArea().transferRecords,
        icon: 'icon_conversion_sidebar',
        isIcon: true,
      },
      {
        isDisabled: !isLogin,
        name: t`features_home_component_switch_router_index_55a8dpaxkh`,
        id: commonMenuTypeEnum.report,
        router: EntertainmentArea().reportRecords,
        icon: 'icon_report_statistics_sidebar',
        isIcon: true,
      },
      {
        isDisabled: false,
        name: t`user.field.reuse_08`,
        id: commonMenuTypeEnum.setting,
        router: getSettingsRoutePath(),
        icon: 'icon_cb_shezhi',
        isIcon: true,
      },
    ]
    setSwitchRouterData(data)
  }, [isLogin, walletModeCd])

  return (
    <div className={styles['layout-menu-wrap']}>
      <div className={`layout-menu-content ${props.onlyIcons ? 'layout-menu-content-hidden' : ''}`}>
        <div className="fixed-top">
          <div className="menu-header-wrap">
            <div
              className={classNames('menu-header-icon', {
                'mr-2': !props.onlyIcons,
              })}
              onClick={() => props?.triggerOpen && props.triggerOpen()}
            >
              <Icon name="nav_icon_liulang" className="retract-icon" />
            </div>
            {!props.onlyIcons && (
              <div
                style={{ backgroundImage: `url("${homeBtnBg || ''}")` }}
                className="menu-home-btn"
                onClick={() => link(getGameLobbyRoutePath())}
              >
                <LazyImage src={`${oss_svg_image_domain_address}icon_home_button.png`} className="menu-home-icon" />
                <label className="menu-home-text">{t`features_home_component_switch_router_index_zcydywzb7d`}</label>
              </div>
            )}
          </div>

          {props.onlyIcons && (
            <div
              className="menu-home-cell"
              style={{ backgroundImage: `url("${homeIconBg || ''}")` }}
              onClick={() => link(getGameLobbyRoutePath())}
            />
          )}
        </div>

        <div className="scroll-wrap">
          {/* <div className="menu-wallet-balance">
          <div className={`wallet-balance-show`}>
            <Icon name="icon_wallet_sidebar" className="wallet-icon" />
            <label>{'钱包余额'}</label>
            <Icon name="icon_eyes" className="wallet-show-icon" />
          </div>
          <div className={`wallet-balance-footer ${isShow ? 'wallet-balance-footer-hidden' : ''}`}>
            <div className="wallet-left">
              <label>{`1,234.14 ${coinId}`}</label>
              <Trigger
                position="bottom"
                trigger={'click'}
                popup={() => (
                  <div className={styles['trigger-layout-menu-wrap']}>
                    {layoutCoinList?.map(item => {
                      return (
                        <div
                          key={item}
                          className={`items ${item === coinId ? 'active-item' : ''}`}
                          onClick={() => setCoinId(item)}
                        >
                          {item}
                        </div>
                      )
                    })}
                  </div>
                )}
              >
                <Icon name="icon_arrow_down" className="wallet-left-icon" />
              </Trigger>
            </div>
            <div className="wallet-right">{'充值'}</div>
          </div>
        </div> */}

          <Commonly onlyIcons={props.onlyIcons} />

          {menuGameList?.length ? (
            <LayoutMenuCard
              data={menuGameList}
              isShowIcon={props.onlyIcons}
              title={props.onlyIcons ? '' : t`features_layout_content_compnent_layout_menu_index_wcj07cbmvk`}
              onChange={v => link(getGameCategoriesRoutePath({ ...v }))}
            />
          ) : null}
          <LayoutMenuCard isShowIcon={props.onlyIcons} data={gameSupplierMenu} onChange={v => link(v?.router)} />
          <LayoutMenuCard isShowIcon={props.onlyIcons} data={switchRouterData} onChange={v => link(v?.router)} />
        </div>
      </div>
    </div>
  )
}
