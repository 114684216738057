import Icon from '@/components/icon'
import { Image, Tooltip } from '@nbit/arco'
import { LayoutScrollTop } from '@/constants/game'
import { ReactNode, useEffect, useState } from 'react'
import { usePageContext } from '@/hooks/use-page-context'
import { useCommonStore } from '@/store/common'
import { ThemeEnum } from '@/constants/base'
import cs from 'classnames'
import { RaHallGameTypeProps, GameOtherTypeProps } from '@/typings/api/game'
import { LayoutMenuProps } from '@/features/layout/content/compnent/layout-menu/menu-main'
import styles from './index.module.css'

type MenuCardProps = {
  /** 菜单数据 */
  data: LayoutMenuProps[] | RaHallGameTypeProps[] | GameOtherTypeProps[]
  /** 标题（不传默认不展示标题） */
  title?: ReactNode
  /** 标题栏 icon */
  titleIcon?: string
  /** 是否只展示菜单 icon */
  isShowIcon?: boolean
  className?: string
  /** 菜单点击事件 */
  onChange?: (v) => void
  /** 标题栏 icon 点击事件 */
  onTitleIconClick?: () => void
}

export default function MenuCard2z({
  data,
  title,
  titleIcon,
  isShowIcon,
  className,
  onChange,
  onTitleIconClick,
}: MenuCardProps) {
  const { theme } = useCommonStore()
  const [routerId, setRouterId] = useState<string>('')
  const [currentRouter, setCurrentRouter] = useState<string>('')
  const pageContext = usePageContext()

  useEffect(() => {
    const { path, urlParsed } = pageContext
    const { search } = urlParsed
    const { typeCd } = search
    if (currentRouter !== path) {
      setCurrentRouter(path)
    }
    if (typeCd !== routerId) {
      setRouterId(typeCd)
    }
  }, [pageContext, data])

  return (
    <div className={cs(styles['menu-card-wrap'], className)}>
      {title && (
        <div className="card-title">
          <div className="title">{title}</div>
          {titleIcon && <Icon name={titleIcon} onClick={() => onTitleIconClick?.()} />}
        </div>
      )}
      {data?.map(item => {
        if (item?.isHide) return null
        return (
          <Tooltip
            className={styles['tooltip-wrap']}
            key={item?.id || item?.value}
            content={item?.name}
            disabled={!isShowIcon}
            position="right"
          >
            <div
              onClick={() => {
                if (item?.isDisabled) return
                onChange?.(item)
                LayoutScrollTop()
              }}
              className={`items ${
                (routerId && Number(routerId) === item?.id) || (currentRouter && currentRouter === item?.router)
                  ? 'menu-active-items'
                  : ''
              } ${item?.isDisabled ? 'menu-active-disabled' : 'menu-active-no'} ${isShowIcon ? 'justify-center' : ''}`}
            >
              {item?.isIcon ? (
                <Icon name={item?.icon} className={`${item?.isDisabled ? 'item-icon-disabled' : 'item-icon'}`} />
              ) : (
                <Image
                  preview={false}
                  src={theme === ThemeEnum.dark ? item?.icon : item?.whiteIcon || item?.icon}
                  className={`items-image ${!item?.whiteIcon && theme === ThemeEnum.light ? 'icon-light' : ''}`}
                />
              )}
              {!isShowIcon && <label>{item?.name || ''}</label>}
              {item.id === 'user' && (
                // (item?.iconUp ? (
                //   <Icon name={'icon_arrow_up'} className="item-icon" />
                // ) : (
                //   <Icon name={'icon_arrow_down'} className="item-icon" />
                // ))
                <div className={cs('dropdown-icon', { 'icon-up': item?.iconUp }, { small: isShowIcon })}>
                  <Icon name={'icon_arrow_up'} className="item-icon" />
                </div>
              )}
            </div>
          </Tooltip>
        )
      })}
    </div>
  )
}
