import Footer from '@/features/recreation/component/footer'
import React from 'react'
import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'
import { useLayoutStore } from '@/store/layout'
import LazyImage from '@/components/lazy-image'
import { getOssManyBusinessImageAddress } from '@/constants/oss'
import {
  YapiGetV1HomeColumnGetListChildColumnsListColumnsDatasData,
  YapiGetV1HomeColumnGetListColumnsDatasData,
} from '@/typings/yapi/HomeColumnGetListV1GetApi'
import { t } from '@lingui/macro'
import style2z from '@/features/recreation/theme/2z/game-footer/index.module.css'
import styleOk from '@/features/recreation/theme/ok/game-footer/index.module.css'

export default function LayoutFooter() {
  const { footerData } = useLayoutStore()
  const { businessName, webCopyright } = footerData || {} // useFooter(data)
  let text = ''
  let contactUsData = {}
  let bottomClass: CSSModuleClasses
  let bootomNode: JSX.Element
  const contactUsData2z = {
    isWeb: 1,
    homeColumnName: t`features_layout_footer_5101300`,
    childColumns: [
      {
        homeColumnName: t`features_layout_footer_index_5101337`,
        isWeb: 1,
        homeColumnCd: 'emailCustomer',
      },
    ] as YapiGetV1HomeColumnGetListChildColumnsListColumnsDatasData[],
  } as YapiGetV1HomeColumnGetListColumnsDatasData

  const contactUsDataOK = {
    isWeb: 1,
    homeColumnName: t`features_layout_footer_5101300`,
    childColumns: [
      {
        homeColumnName: t`features_layout_footer_index_5101337`,
        isWeb: 1,
        homeColumnCd: 'emailCustomer',
      },
      {
        isWeb: 1,
        homeColumnName: t`features_layout_footer_index_5101338`,
        homeColumnCd: 'emailProduct',
      },
      {
        isWeb: 1,
        homeColumnName: t`features_layout_footer_index_5101339`,
        homeColumnCd: 'emailBusiness',
      },
      {
        isWeb: 1,
        homeColumnName: t`features_layout_footer_index_5101340`,
        homeColumnCd: 'emailJudiciary',
      },
    ] as YapiGetV1HomeColumnGetListChildColumnsListColumnsDatasData[],
  } as YapiGetV1HomeColumnGetListColumnsDatasData

  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      text = `© ${webCopyright}`
      contactUsData = contactUsData2z
      break
    case InitThemeColor.ok:
      text = `${businessName} © ${webCopyright}`
      contactUsData = contactUsDataOK
      break
    default:
      text = `© ${webCopyright}`
      contactUsData = contactUsData2z
      break
  }

  const bootomNode2z = () => (
    <div className="footer-bootom">
      <div className="logo-img">
        <LazyImage hasTheme src={`${getOssManyBusinessImageAddress()}locale_logo.png`} />
      </div>
      <div>{text}</div>
    </div>
  )
  const bootomNodeOk = () => <div className="footer-bootom">{text}</div>
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      bootomNode = bootomNode2z()
      bottomClass = style2z
      break
    case InitThemeColor.ok:
      bootomNode = bootomNodeOk()
      bottomClass = styleOk
      break
    default:
      bootomNode = bootomNode2z()
      bottomClass = style2z
      break
  }
  return <Footer className={bottomClass.scoped} contactUsData={contactUsData} bootomNode={bootomNode} />
}
