import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import { useGameStore } from '@/store/game'
import { LayoutScrollTop } from '@/constants/game'
import { useCommonStore } from '@/store/common'
import { MaintenanceEnum } from '@/constants/maintenance'
import { getLastThemeLastType } from '@/helper/cache/game'
import styles from './index.module.css'

export default function SwitchGame() {
  const { maintenance } = useGameStore()
  const { setThemeType, setIsWelcomeHomeShow } = useCommonStore()
  const lastType = getLastThemeLastType()

  return (
    <div className={styles['switch-game-wrap']}>
      <div
        className="switch-game-text switch-game-left"
        onClick={() => {
          link('/')
          setIsWelcomeHomeShow(true)
          lastType && setThemeType(lastType)
        }}
      >
        {t`future.funding-history.index-price.ingredient.column.exchange`}
      </div>
      <div
        className="switch-game-text switch-game-right"
        onClick={() => {
          if (maintenance !== MaintenanceEnum.normal) return
          LayoutScrollTop()
          link('/recreation')
        }}
      >
        {t`features_layout_header_components_switch_game_index_3jxunqhhyq`}
      </div>
    </div>
  )
}
