import { appendImagePath } from '@/helper/layout'
import { CSSProperties, useMemo } from 'react'
import { useCommonStore } from '@/store/common'
import { ThemeEnum } from '@/constants/base'
import cs from 'classnames'

import { Tooltip } from '@nbit/arco'
import styles from './index.module.css'

export interface IEntryButtonProps {
  label: string
  bgImg?: string
  active?: boolean
  activeBgImg?: string
  className?: string
  showTooltip?: boolean
  onClick?: () => void
}

function EntryButton({
  label,
  bgImg = '',
  active = false,
  showTooltip = false,
  activeBgImg,
  className,
  onClick,
}: IEntryButtonProps) {
  const { theme } = useCommonStore()
  const style: CSSProperties = useMemo(() => {
    const isDark = theme === ThemeEnum.dark
    const activeBackgroundImage = activeBgImg || appendImagePath(bgImg)
    const backgroundImage = active ? activeBackgroundImage : isDark ? bgImg : appendImagePath(bgImg, '_light')
    const activeStyle = activeBackgroundImage ? { '--active-bg': `url(${activeBackgroundImage})` } : {}
    return backgroundImage ? { '--normal-bg': `url(${backgroundImage})`, ...activeStyle } : {}
  }, [theme, active, bgImg, activeBgImg])

  const buttonEl = (
    <div className={cs(styles['entry-button'], className)} style={style} onClick={onClick}>
      <div className="button-label">{showTooltip ? '' : label}</div>
    </div>
  )

  return showTooltip ? (
    <Tooltip content={label} position="right">
      {buttonEl}
    </Tooltip>
  ) : (
    buttonEl
  )
}

export default EntryButton
