import { t } from '@lingui/macro'

function SingleFiatCurrency(props) {
  const { isGameDetailsPage, currentCurrency, totalAmount } = props
  return (
    <div className="out-box">
      <div className="balance-style">
        <span className="balance-amount">
          {`${
            isGameDetailsPage ? t`features_layout_recreation_header_components_wallet_index_5f613dyizg` : totalAmount
          } ${currentCurrency?.currencyEnName || '--'}`}
        </span>
      </div>
    </div>
  )
}
export default SingleFiatCurrency
