import React, { useEffect } from 'react'
import { I18nsEnum } from '@/constants/i18n'
import { configResponsive } from 'ahooks'
import LayoutMain from '@/features/recreation/theme/components/layout'

export default Layout

function Layout({ pageContext, children }: { pageContext: PageContext; children: React.ReactNode }) {
  const locale = pageContext.locale

  configResponsive({
    lg: 1200,
    xl: 1440,
    xxl: 1850,
    xxxl: 2560,
  })

  useEffect(() => {
    // 检测用户的操作系统
    const userAgent = window.navigator.userAgent
    const isWindows = userAgent.includes('Windows')

    // 设置 HTML 标签的字体大小为 13px
    if (isWindows && locale === I18nsEnum['ta-IN']) {
      document.documentElement.style.fontSize = '13px'
    }
  }, [locale])

  return <LayoutMain pageContext={pageContext}>{children}</LayoutMain>
}
