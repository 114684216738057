import Layout2z from '@/features/recreation/theme/2z/layout2z'
import LayoutOk from '@/features/recreation/theme/ok/layoutOk'
import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'

export default function LayoutMain({ pageContext, children }: { pageContext: PageContext; children: React.ReactNode }) {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return <Layout2z pageContext={pageContext}>{children}</Layout2z>
    case InitThemeColor.ok:
      return <LayoutOk pageContext={pageContext}>{children}</LayoutOk>
    default:
      return <Layout2z pageContext={pageContext}>{children}</Layout2z>
  }
}
