/**
 * 欢迎页
 */
import { t } from '@lingui/macro'
import LazyImage from '@/components/lazy-image'
import { oss_ra_image_domain_address } from '@/constants/oss'
import { memo, useEffect } from 'react'
import { usePageContext } from '@/hooks/use-page-context'
import classNames from 'classnames'
import { useCommonStore } from '@/store/common'
import styles from './index.module.css'
import { welcomeLocaleData } from './welcome_locale_config'

function WelcomeLayout() {
  const pageContext = usePageContext()
  const { locale, theme, isWelcomeRereationShow, setIsWelcomeRereationShow } = useCommonStore()
  const { locale: localSearch } = pageContext?.urlParsed?.search || {}

  const getLanguageConfig = _locale => {
    return welcomeLocaleData[_locale]
  }

  const getLanguageByKey = key => {
    if (!localSearch || localSearch === locale) {
      return {
        features_welcome_index_qbavhqn5lu: t`features_welcome_index_qbavhqn5lu`,
        features_layout_header_components_switch_game_index_3jxunqhhyq: t`features_layout_header_components_switch_game_index_3jxunqhhyq`,
      }[key]
    }
    return getLanguageConfig(localSearch)[key]
  }

  useEffect(() => {
    if (isWelcomeRereationShow) {
      const timer = setTimeout(() => {
        setIsWelcomeRereationShow(false)
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [isWelcomeRereationShow])

  if (!isWelcomeRereationShow) return null

  return (
    <div className={classNames(styles['welcome-root'], 'fade-out')}>
      <div className="welcome-main">
        <div className="welcome-desc">
          <div>{getLanguageByKey('features_welcome_index_qbavhqn5lu')}</div>
          <div className="name">
            {getLanguageByKey('features_layout_header_components_switch_game_index_3jxunqhhyq')}
          </div>
        </div>
        <div>
          <LazyImage className="welcome-image" src={`${oss_ra_image_domain_address}welcome_img_${theme}.png`} />
        </div>
      </div>
    </div>
  )
}

export default memo(WelcomeLayout)
