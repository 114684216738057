import Link from '@/components/link'
import { determineRedirectionUrl } from '@/helper/layout/footer'
import { useLayoutStore } from '@/store/layout'
import { TlayoutProps } from '@/typings/api/layout'
import { YapiGetV1HomeColumnGetListColumnsDatasData } from '@/typings/yapi/HomeColumnGetListV1GetApi'
import { useCommonStore } from '@/store/common'
import { AppNameEnum } from '@/constants/marketing/fission-activity'
import { LinkValEnum, GameContactUsEnum } from '@/constants/locale-config'
import { AdlocaleConfigResp, LinkUsType } from '@/typings/common'
import { t } from '@lingui/macro'
import { getNewWhatsAppWebRoutePath } from '@/helper/route/marketing'

type TFooterMenuList = {
  list: YapiGetV1HomeColumnGetListColumnsDatasData['childColumns']
}

function FooterMenuList(props: TFooterMenuList) {
  const { list } = props
  const { localeInfo } = useCommonStore()
  const { layoutProps } = useLayoutStore()
  function contactUsDataList(val): LinkUsType[] {
    if (localeInfo?.home?.footer?.linkUs && localeInfo?.home?.footer?.linkUs.length > 0) {
      return localeInfo?.home?.footer?.linkUs?.map(res => {
        if (res?.value === LinkValEnum.emailCustomer) {
          res.nameKey = !res?.showAddress
            ? AppNameEnum.email
            : `${t`features_layout_footer_index_5101337`}: ${val[res?.value] || ''}`
          res.href = `mailto:${val[res?.value] || ''}`
        }

        if (res?.value === LinkValEnum.whatsLink) {
          const whatsappInvitLink = (layoutProps && [layoutProps[res?.value]]) || []
          res.nameKey = !res?.showAddress ? AppNameEnum.whatsApp : val[res?.value] || ''
          res.href = getNewWhatsAppWebRoutePath('', whatsappInvitLink)
        }
        return res
      })
    }
    return []
  }

  return (
    <>
      {list.map(each =>
        each.isWeb === GameContactUsEnum.isWeb
          ? contactUsOrLink(each, contactUsDataList(layoutProps), layoutProps, localeInfo)
          : null
      )}
    </>
  )
}

function contactUsOrLink(
  data: YapiGetV1HomeColumnGetListColumnsDatasData['childColumns'][0],
  contactUsDataList: LinkUsType[],
  layoutProps?: TlayoutProps,
  localeInfo?: AdlocaleConfigResp
) {
  if (layoutProps && layoutProps[data.homeColumnCd]) {
    const emailKey = data.homeColumnCd
    return localeInfo?.home?.footer?.linkUs && localeInfo?.home?.footer?.linkUs.length > 0 ? (
      <>
        {contactUsDataList.map((res, index) => {
          return (
            <span key={index} className="menu-list">
              <a href={res?.href} className="hover:text-brand_color whitespace-nowrap">
                {res?.nameKey}
              </a>
            </span>
          )
        })}
      </>
    ) : (
      <span key={data.homeColumnName} className="menu-list">
        <a href={`mailto:${layoutProps[emailKey]}`} className="hover:text-brand_color whitespace-nowrap">
          {`${data.homeColumnName}: ${layoutProps[emailKey]}`}
        </a>
      </span>
    )
  }
  return (
    <span key={data.homeColumnName} className="menu-list">
      <Link href={determineRedirectionUrl(data)} target={data.isLink === 1} className="hover:text-brand_color">
        {data.homeColumnName}
      </Link>
    </span>
  )
}

export default FooterMenuList
