import { useState, useEffect } from 'react'
import { useSize } from 'ahooks'
import { Drawer } from '@nbit/arco'
import { useLayoutStore } from '@/store/layout'
import MenuMain from '@/features/recreation/theme/ok/layout/content/compnent/layout-menu/menu-main'

export default function LayoutMenu() {
  const layoutStore = useLayoutStore()

  // 是否显示右侧抽屉
  const [showLeftDrawer, setShowLeftDrawer] = useState(false)

  // 是否窄屏，小于 1200 为窄屏
  const { narrowScreen } = layoutStore

  // 是否展开
  const [isOpen, setIsOpen] = useState(true)

  const bodySize = useSize(() => document.querySelector('body'))

  const triggerOpen = () => {
    const newIsOpen = !isOpen
    setIsOpen(newIsOpen)
    setShowLeftDrawer(narrowScreen && newIsOpen)
  }

  useEffect(() => {
    if (bodySize) {
      const currentWidth = bodySize?.width || 0
      const isNarrow = currentWidth < 1200
      layoutStore.setNarrowScreen(isNarrow)
      setShowLeftDrawer(isNarrow && isOpen)
    }
  }, [bodySize])

  return (
    <>
      <MenuMain onlyIcons={narrowScreen || !isOpen} triggerOpen={triggerOpen} />
      <Drawer
        width={240}
        visible={showLeftDrawer}
        closable={false}
        placement="left"
        title={null}
        onCancel={() => {
          triggerOpen()
        }}
        footer={null}
      >
        <MenuMain triggerOpen={triggerOpen} />
      </Drawer>
    </>
  )
}
