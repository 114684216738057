import { CurrencyModeErationEnum } from '@/constants/common'
import { useCommonStore } from '@/store/common'
import MultiCurrency from './multi-currency'
import SingleCurrency from './single-currency'
import SingleFiatCurrency from './single-fiat-currency'

function WalletCurrency(props) {
  const { localeInfo } = useCommonStore()
  switch (localeInfo?.currencyMode) {
    case CurrencyModeErationEnum.singleCurrency:
      return <SingleCurrency {...props} />
    case CurrencyModeErationEnum.multiCurrency:
      return <MultiCurrency {...props} />
    case CurrencyModeErationEnum.singleFiatCurrency:
      return <SingleFiatCurrency {...props} />
    default:
      return <SingleCurrency {...props} />
  }
}

export default WalletCurrency
