import { ReactNode } from 'react'
import Footer from '@/features/recreation/theme/components/footer'
import LayoutMenu from '@/features/layout/content/compnent/layout-menu'
import { getFooterShow } from '@/helper/layout/footer'
import classNames from 'classnames'
import { MaintenanceEnum } from '@/constants/maintenance'
import { baseGameStore } from '@/store/game'
import { baseLayoutStore } from '@/store/layout'
import styles from './index.module.css'

type ContentProps = {
  pageContext: PageContext
  children: ReactNode
}

const onRefLoad = (el: HTMLDivElement | null) => {
  const layoutStore = baseLayoutStore.getState()
  layoutStore.setContentDom(el)
}

export default function content({ pageContext, children }: ContentProps) {
  const footerShow = getFooterShow(pageContext)
  const { maintenance } = baseGameStore.getState() // 娱乐区维护状态获取

  return (
    <section className={classNames(styles['content-wrap'], { 'is-normal': maintenance !== MaintenanceEnum.normal })}>
      {pageContext.layoutParams?.menuShow !== false ? (
        <>
          {/* {maintenance === MaintenanceEnum.normal && (
            <div className="content-wrap-left">
              <LayoutMenu />
            </div>
          )} */}
          <div className="content-wrap-right" id="pageContentRight" ref={onRefLoad}>
            <div className="layout-content">{children}</div>
            {(maintenance === MaintenanceEnum.normal || footerShow) && (
              <div className="footer-content-box">
                <Footer />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {children}
          {(maintenance === MaintenanceEnum.normal || footerShow) && (
            <div className="footer-content-box">
              <Footer />
            </div>
          )}
        </>
      )}
    </section>
  )
}
